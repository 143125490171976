import React, { useState, useEffect } from 'react';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCityData } from '../../utils/city-data';
import { useGetShopsQuery } from '../../modules/shops/api';
import ShopCard from '../cards/ShopCard';
import Spinner from '../spinner/Spinner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ShopSlider = ({ city }) => {
    const cityData = getCityData(city);
    const { data: shops, isLoading, isError } = useGetShopsQuery(cityData.url);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <p>Error loading shops</p>;
    }

    return (
        <Swiper
            modules={[Autoplay, FreeMode]}
            slidesPerView={2}
            spaceBetween={30}
            freeMode={false}
            loop={false}
            className="mySwiper row"
        >
            {shops.data.map((shop, index) => (
                <SwiperSlide key={index}>
                    <ShopCard
                        key={index}
                        name={shop.name}
                        imageUrl={shop.media.length > 0 ? shop.media[0].url : ''}
                        link={`/City-${city}/shop/shop-${shop.name}`}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ShopSlider;

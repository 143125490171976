import React from 'react';
import Layout from '../components/layout/Layout';
import { getAllCities } from '../utils/city-data';

function HomePage() {
  const cityData = getAllCities();

  return (
    <Layout>
      <section className="home-banner row justify-content-between">
        <div className="l-element col-4"></div>
        <div className="c-element col-4">
          <div className="c-element-image self-center"></div>
          <p className="main-banner-text text-center">
            АГРЕГАТОР ДОСТАВКИ
            <br />
            ИЗ РЕСТОРАНОВ
            <br />
            ВАШЕГО ГОРОДА
          </p>
        </div>
        <div className="r-element col-4"></div>
      </section>
      <section className="city-space">
        <p className="d-flex justify-content-center align-content-center block-banner-text">
          <b>МЫ <span className="text-deep-orange">РАБОТАЕМ</span> В ГОРОДАХ</b>
        </p>
        <div className="d-flex justify-content-center align-content-center">
          <div className="d-flex justify-content-between main-block-cities">
            {Object.keys(cityData).map((cityName) => (
              <a
                key={cityName}
                className="q-btn q-btn--standard bg-deep-orange text-white q-btn--actionable"
                tabIndex="0"
                href={`/City-${cityName}/`}
              >
                {cityData[cityName].name}
              </a>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;

import React from 'react';

const ShopCard = ({ name, imageUrl, link }) => {
    return (
        <li className="section-restaurants__item">
            <a href={link}>
                <div className="section-restaurants__block-img">
                    <img src={imageUrl} alt="Магазин" className="section-restaurants__img" />
                </div>
                <div className="section-restaurants__block-text restaurant-text">
                    <p className="section-restaurants__text">{name}</p>
                </div>
            </a>
        </li>
    );
};

export default ShopCard;

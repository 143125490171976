import React, { useState, useEffect } from 'react';
import CartItem from './CartItem';
import eventBus from '../../eventBus';
import useCartTotalPrice from '../../hooks/useCartTotalPrice';

function CartList() {
    const [cartItems, setCartItems] = useState([]);
    const [restaurantName, setRestaurantName] = useState('');
    const [deliveryFeeText, setDeliveryFeeText] = useState(0);
    const [isUsingShop, setIsUsingShop] = useState(false);
    const { total, deliveryFee } = useCartTotalPrice();

    const handleCartItemAdded = (data) => {
        setCartItems(prevCartItems => [...prevCartItems, data]);
    };

    const handleRemoveItem = (itemId) => {
        const removedItem = cartItems.find(
            (item) => item.uniqueId === itemId
        );

        if (removedItem) {
            eventBus.publish('cartItemRemoved', removedItem);

            const updatedCart = cartItems.filter(
                (item) => item.uniqueId !== itemId && item.product_id !== itemId
            );

            setCartItems(updatedCart);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));

            if (updatedCart.length === 0) {
                localStorage.removeItem('cartRestaurantInfo');
                localStorage.removeItem('cartShopInfo');
            }
        }
    };

    const handleRemoveExtra = (itemId, extraId) => {
        const removedExtra = cartItems.find((item) => item.uniqueId === itemId)?.extras.find((extra) => extra.id === extraId);

        if (removedExtra) {
            eventBus.publish('cartExtraRemoved', { food_id: itemId, extra: removedExtra });

            const updatedCart = cartItems.map((item) => {
                if (item.uniqueId === itemId) {
                    const updatedExtras = item.extras.filter((extra) => extra.id !== extraId);
                    return { ...item, extras: updatedExtras };
                }
                return item;
            });

            setCartItems(updatedCart);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
        }
    };

    const handleUpdateQuantity = (itemId, newQuantity) => {
        const updatedCart = cartItems.map((item) =>
            item.uniqueId === itemId ? { ...item, quantity: newQuantity } : item
        );

        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));

        const cartItemToUpdate = cartItems.find(
            (item) => item.uniqueId === itemId
        );

        if (cartItemToUpdate) {
            const priceChange =
                (newQuantity - cartItemToUpdate.quantity) * cartItemToUpdate.price;

            eventBus.publish('cartItemQuantityUpdated', {
                food_id: itemId,
                product_id: cartItemToUpdate.product_id,
                localQuantity: newQuantity,
                priceChange,
            });
        }
    };

    const handleUpdateExtraQuantity = (itemId, extraId, newQuantity) => {
        const updatedCart = cartItems.map((item) => {
            if (item.uniqueId === itemId) {
                const updatedExtras = item.extras.map((extra) =>
                    extra.id === extraId ? { ...extra, count: newQuantity } : extra
                );
                return { ...item, extras: updatedExtras };
            }
            return item;
        });

        setCartItems(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));

        eventBus.publish('cartItemQuantityUpdated', { food_id: itemId, localQuantity: newQuantity });
    };

    const updateDeliveryFee = () => {
        if (deliveryFee > 0) {
            setDeliveryFeeText(deliveryFee);
        } else {
            setDeliveryFeeText('бесплатно');
        }
    }

    const setBusinessInfo = (info) => {
        if (!info) {
            return;
        }

        const name = info.restaurantName || info.shopName;

        setRestaurantName(name);
        setIsUsingShop(!!info.shopName);
    };

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(() => storedCart);

        eventBus.subscribe('cartItemAdded', handleCartItemAdded);

        const restaurantInfo = JSON.parse(localStorage.getItem('cartRestaurantInfo')) || [];
        const shopInfo = JSON.parse(localStorage.getItem('cartShopInfo')) || [];

        setBusinessInfo(restaurantInfo && restaurantInfo.restaurantName ? restaurantInfo : shopInfo);

        updateDeliveryFee();

        return () => {
            eventBus.unsubscribe('cartItemAdded', handleCartItemAdded);
        };
    }, [total]);

    return (
        <div className="box">
            <div className="q-item q-item-type row no-wrap">
                <div className="q-item__section column q-item__section--main justify-content-center">
                    <div className="q-item__label q-item__label--caption text-caption">Ваш заказ в {isUsingShop ? 'магазине' : 'ресторане'}</div>
                    <div className="q-item__label"><span className="text-weight-medium">{restaurantName}</span></div>
                </div>
            </div>
            <hr />
            <div className="cart_element">
                {cartItems.map((item, index) => (
                    <CartItem
                        key={index}
                        {...item}
                        onRemove={handleRemoveItem}
                        onUpdateQuantity={handleUpdateQuantity}
                        onRemoveExtra={handleRemoveExtra}
                        onUpdateExtraQuantity={handleUpdateExtraQuantity}
                    />
                ))}
            </div>
            <div className="q-item q-item-type d-flex no-wrap">
                <div className="q-item__section d-flex q-item__section--main">
                    <div className="q-item__label">Доставка</div>
                </div>
                <div className="q-item__section d-flex q-item__section--side justify-content-center">
                    <div className="q-item__label">{deliveryFeeText} ₽</div>
                </div>
            </div>
        </div>
    );
}

export default CartList;

import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import RightPanel from './RightPanel';

function Layout({ children, city }) {
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const toggleRightPanel = () => {
    setRightPanelOpen(!rightPanelOpen);
  };

  const closeRightPanel = () => {
    setRightPanelOpen(false);
  };

  return (
    <div className="layout-container">
      <Header city={city} toggleRightPanel={toggleRightPanel} />

      <main className={`main-content ${city ? '' : 'no-city'}`}>
        {children}
      </main>

      <Footer city={city} />

      {city && (
        <RightPanel isOpen={rightPanelOpen} onClose={closeRightPanel} city={city} />
      )}
    </div>
  );
}

export default Layout;

import React from 'react';
import { getCityData } from '../../utils/city-data';
import useCartTotalPrice from '../../hooks/useCartTotalPrice';

function Header({ city, toggleRightPanel }) {
    const { total } = useCartTotalPrice();
    const cityData = getCityData(city);

    const handleToggleRightPanel = () => {
        if (typeof toggleRightPanel === 'function') {
            toggleRightPanel();
        }
    };

    return (
        <header className="q-header q-layout__section--marginal fixed-top header">
            <div className="q-toolbar row no-wrap align-items-center">
                <div className="q-toolbar__title ellipsis">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-3 col-md-3 d-flex align-items-center justify-content-center">
                            <a href="/">
                                <img src="/assets/images/logo.jpg" alt="лого" className="header__item-logo-img" />
                            </a>
                        </div>
                        {city && cityData && (
                            <div className="col-4 col-sm-3 col-md-3 margin-bot-5 d-flex align-items-center">
                                <a className="btn btn-primary button_cart" tabIndex="0" href="/"><span>На Главную</span></a>
                            </div>
                        )}
                        {city && cityData && (
                            <div className="col-5 col-sm-3 col-md-3 margin-bot-5 d-flex align-items-center justify-content-center">
                                <span className="city-rest-name">{cityData.name}</span>
                            </div>
                        )}
                        {city && cityData && (
                            <div className="col-3 col-sm-3 col-md-3 margin-bot-5 d-flex justify-content-end">
                                <button className="btn btn-primary d-flex align-items-center gap-2 button_cart" tabIndex="0" type="button" onClick={handleToggleRightPanel}>
                                    <i className="fas fa-shopping-basket fs-4" aria-hidden="true" role="presentation"> </i>
                                    <span>{total} ₽</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;

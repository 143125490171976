import React, { useState, useEffect } from 'react';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCityData } from '../../utils/city-data';
import { useGetSlidesQuery } from '../../modules/slides/api';
import Spinner from '../spinner/Spinner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Slider = ({ city }) => {
    const [isMobile, setIsMobile] = useState(false);
    const cityData = getCityData(city);
    const { data: slides, isLoading, isError } = useGetSlidesQuery(cityData.url);

    useEffect(() => {
        // Определение типа устройства
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <p>Error loading slides</p>;
    }

    return (
        <Swiper
            modules={[Autoplay, FreeMode]}
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            loop={true}
            className="mySwiper row"
        >
            {slides.data.map((slide, index) => (
                <SwiperSlide key={index}>
                    {slide.restaurant_id ? (
                        <a href={`/City-${cityData.urlName}/shop-${slide.restaurant_name}`}>
                            <img
                                src={slide.media[0].url}
                                className="img-fluid mx-auto d-block"
                                alt={`img${index}`}
                                style={{ borderRadius: '10px' }}
                            />
                        </a>
                    ) : (
                        <img
                            src={slide.media[0].url}
                            className="img-fluid mx-auto d-block"
                            alt={`img${index}`}
                            style={{ borderRadius: '10px' }}
                        />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Slider;

import React, { useState } from 'react';
import { useGetShopCategoryProductsQuery } from '../../modules/shops/api';
import { getCityData } from '../../utils/city-data';
import ProductItem from '../cards/ProductItem';
import Spinner from '../spinner/Spinner';
import AlertModal from '../modal/AlertModal';
import { useInView } from 'react-intersection-observer';

const CategoryProductsList = ({ city, categoryId, shopId, categoryName }) => {
  const cityData = getCityData(city);
  const params = { city: cityData.url, category_id: categoryId, shop_id: shopId };
  const { data: products, isLoading, isError } = useGetShopCategoryProductsQuery(params);

  const [modalData, setModalData] = useState(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const showAlertModal = (title, message) => {
    setModalData({ title, message });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>Error loading shops</p>;
  }

  const productsArray = Object.values(products);

  return (
    <div ref={ref} className='category-foods-block'>
      {inView && (
        <>
          {modalData && <AlertModal title={modalData.title} message={modalData.message} onClose={() => setModalData(null)} />}

          <h2 className="second-heading">{categoryName}</h2>
          <ul className="row justify-content-start">
            {productsArray.map((product, index) => (
              <ProductItem key={index} {...product} showAlertModal={showAlertModal} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CategoryProductsList;

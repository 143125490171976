import React from 'react';
import { CityProvider } from '../../contexts/CityContext';
import WithCityParam from './WithCityParam';

function CityRoute({ children }) {
  return (
    <WithCityParam>
      {({ city }) => (
        <CityProvider city={city}>
          {children}
        </CityProvider>
      )}
    </WithCityParam>
  );
}

export default CityRoute;

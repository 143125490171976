import { parentApi } from '../../fetcher';

export const restaurantsApi = parentApi.injectEndpoints({
    endpoints: (builder) => ({
        getRestaurants: builder.query({
            query: (city) => `${city}/restaurants?searchJoin=and`,
            providesTags: ['RESTAURANTS'],
        }),
        getRestaurantByName: builder.query({
            query: (params) => {
                const { city, restaurantName } = params;
                const filterFields = 'id;name;available_for_delivery;minimum_sum_of_delivery_free;closed;delivery_fee;week_schedule;work_time_start;work_time_end'

                return `${city}/restaurants?search=${restaurantName}&searchFields=name&filter=${filterFields}`;
            },
            providesTags: ['RESTAURANT'],
        }),
        getCategories: builder.query({
            query: (params) => {
                const { city, restaurant_id } = params;
                return {
                    url: `${city}/siteCategoryFood`,
                    method: 'POST',
                    body: { restaurant_id: restaurant_id },
                };
            },
            providesTags: ['CATEGORIES'],
        }),
        getCategoryFoods: builder.query({
            query: (params) => {
                const { city, category_id, restaurant_id } = params;
                return {
                    url: `${city}/siteRestaurantFoodCategory?category_id=${category_id}&restaurant_id=${restaurant_id}`,
                    method: 'POST',
                    body: {
                        category_id: category_id,
                        restaurant_id: restaurant_id
                    },
                };
            },
            providesTags: ['CATEGORIES'],
        }),
    }),
});

export const { useGetRestaurantsQuery, useGetRestaurantByNameQuery, useGetCategoriesQuery, useGetCategoryFoodsQuery } = restaurantsApi;
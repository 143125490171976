import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useGetRestaurantByNameQuery, useGetCategoriesQuery } from '../modules/restaurants/api';
import { getCityData } from '../utils/city-data';
import { useCity } from '../contexts/CityContext';
import CategoryItem from '../components/cards/CategoryItem';
import CategoryFoodsList from '../components/lists/CategoryFoodsList';
import Spinner from '../components/spinner/Spinner';

function RestaurantPage() {
  const { restaurant } = useParams();
  const cityName = useCity();

  const [activeCategory, setActiveCategory] = useState(null);

  const restaurantName = restaurant.replace('shop-', '');
  const cityData = getCityData(cityName);

  const restaurantParams = { city: cityData.url, restaurantName };
  const { data: restaurantData, restaurantIsLoading, restaurantIsError } = useGetRestaurantByNameQuery(restaurantParams);

  const defaultCategory = {
    id: 1,
    sort: 0,
    name: 'Всё меню',
    media: {
      icon: '/assets/images/vse-tovari.png',
    },
  };

  const categoriesParams = { city: cityData.url, restaurant_id: restaurantData?.data[0]?.id || 11 };
  const { data: categoriesData, isLoading: categoriesIsLoading, isError: categoriesIsError } = useGetCategoriesQuery(categoriesParams);

  useEffect(() => {
    if (restaurantData?.data[0]) {
      const {
        id: restaurant_id,
        delivery_fee,
        available_for_delivery,
        minimum_sum_of_delivery_free,
        restaurant_has_worked
      } = restaurantData.data[0];

      const restaurantCity = cityName;

      const restaurantInfo = { restaurant_id, restaurantName, restaurantCity, delivery_fee, available_for_delivery, minimum_sum_of_delivery_free, restaurant_has_worked };

      localStorage.setItem('currentRestaurantInfo', JSON.stringify(restaurantInfo));
      localStorage.removeItem('currentShopInfo');
    }
  }, [restaurantName, restaurantData, cityName]);

  if (restaurantIsLoading || categoriesIsLoading) {
    return <Spinner />;
  }

  if (restaurantIsError || categoriesIsError) {
    return <p>Error loading data</p>;
  }

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId === defaultCategory.id ? null : categoryId);
  };

  return (
    <Layout city={cityName}>
      <div className="section-shop__container">
        <div className="section-shop__products">
          <div className="section-shop__info">
            <div className="section-shop__info-back">
              <a href={`/City-${cityData.urlName}`} className="router-link-active">
                <i className="fas fa-chevron-left"></i> Все рестораны
              </a>
            </div>
            {restaurantData ? (
              <div className="section-shop__info-block">
                <h1 className="section-shop__main-headin">{restaurantData ? restaurantData.data[0].name : ''}</h1>
                <ul className="row text-center">
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      Ресторан <b>{restaurantData.data[0].restaurant_has_worked ? 'ОТКРЫТ' : 'Закрыт'}</b>
                    </span>
                  </li>
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      {restaurantData.data[0].minimum_sum_of_delivery_free ? (
                        <>Бесплатная доставка от <b>{restaurantData.data[0].minimum_sum_of_delivery_free}₽</b></>
                      ) : (
                        <>Доставка <b>{restaurantData.data[0].delivery_fee}₽</b></>
                      )}
                    </span>
                  </li>
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      Платная доставка <b>{restaurantData.data[0].delivery_fee}₽</b>
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <Spinner />
            )}
            <div className="section-shop__categories">
              <ul className="row">
                <CategoryItem {...defaultCategory} isActive={activeCategory === null} onClick={() => handleCategoryClick(null)} />
                {categoriesData[0].map((category, index) => (
                  <CategoryItem
                    key={index}
                    {...category}
                    isActive={category.id === activeCategory}
                    onClick={() => handleCategoryClick(category.id)}
                  />
                ))}
              </ul>
            </div>
            <div className="section-shop__goods">
              <div className="q-gutter-sm">
                {categoriesData[0].map((category, index) => (
                  (activeCategory === null || activeCategory === category.id) && (
                    <CategoryFoodsList
                      key={index}
                      city={cityName}
                      categoryId={category.id}
                      restaurantId={restaurantData?.data[0]?.id || ''}
                      categoryName={category.name}
                    />
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RestaurantPage;

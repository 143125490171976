import React from 'react';
import Layout from '../components/layout/Layout';
import { useCity } from '../contexts/CityContext';

function PaymentFailPage() {
  const city = useCity();

  return (
    <Layout>
      <section className="section_order">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <i className="fa fa-exclamation-circle q-icon text-deep-orange" style={{ fontSize: '200px' }} aria-hidden="true"></i>
            </div>
            <div className="col-12 text-center">
              <h3>Заказ отменен!</h3>
              <div className="section-shop__info-back q-pt-md">
              <a href={`/City-${city}/`} className="router-link-active">
                  <i className="fas fa-chevron-left"></i> На главную страницу
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PaymentFailPage;

import { createContext, useContext } from 'react';

const CityContext = createContext(null);

export function useCity() {
  return useContext(CityContext);
}

export function CityProvider({ children, city }) {
  return <CityContext.Provider value={city}>{children}</CityContext.Provider>;
}

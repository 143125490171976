import React, { useState } from 'react';
import InputMask from '@mona-health/react-input-mask'


const CustomInput = ({
    name,
    label,
    id,
    type = 'text',
    value = '',
    additionalClass = '',
    bottomLabel = '',
    isRequired = false,
    isDisabled = false,
    isCorrect = false,
    isWrong = false,
    isTextarea = false,
    inputMask = false,
    autocomplete = '',
    onChange
}) => {
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
        setError(isRequired && inputValue.trim() === '');
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        onChange(event);

        setError(isRequired && event.target.value.trim() === '');
    };

    const inputElement = isTextarea ? (
        <textarea
            className={`q-field__native q-placeholder`}
            tabIndex="0"
            rows="6"
            aria-label={label}
            id={id}
            name={name}
            autoComplete={autocomplete}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
        ></textarea>
    ) : inputMask ? (
        <InputMask
            mask={inputMask}
            value={value}
            required={isRequired}
            onChange={handleInputChange}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`q-field__native q-placeholder`}
            tabIndex="0"
            aria-label={label}
            id={id}
            name={name}
            type="text"
        />
    ) : (
        <input
            className={`q-field__native q-placeholder`}
            tabIndex="0"
            aria-label={label}
            id={id}
            name={name}
            type={type}
            autoComplete={autocomplete}
            required={isRequired}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
            value={inputValue}
        />
    );

    const labelClasses = `q-field row no-wrap align-items-start q-field--standard q-input q-field--labeled 
        ${additionalClass} 
        ${isFocused ? 'q-field--focused q-field--highlighted q-field--float' : ''} 
        ${value ? 'q-field--float' : ''} 
        ${isDisabled ? 'q-field--disabled' : ''} 
        ${isWrong || error ? 'q-field--error q-field--highlighted' : ''} 
        ${inputValue ? 'q-field--float q-field--focused' : ''}`;

    return (
        <label className={labelClasses} htmlFor={id}>
            <div className="q-field__inner relative-position col self-stretch">
                <div className={`q-field__control relative-position row no-wrap ${isCorrect ? 'text-green' : ''} ${isWrong || error ? 'text-negative' : ''}`} tabIndex="-1">
                    <div className="q-field__control-container relative-position row no-wrap q-anchor--skip">
                        {inputElement}
                        <div className="q-field__label no-pointer-events absolute ellipsis">{label}</div>
                    </div>
                    {isCorrect && (
                        <div className="q-field__append q-field__marginal row no-wrap align-items-center">
                            <i className="fas fa-check-circle q-icon text-green p-0" aria-hidden="true" role="presentation"></i>
                        </div>
                    )}
                    {(isWrong || error) && (
                        <div className="q-field__append q-field__marginal row no-wrap align-items-center q-anchor--skip">
                            <i className="fas fa-exclamation-circle q-icon text-negative p-0" aria-hidden="true" role="presentation"></i>
                        </div>
                    )}
                </div>
                <div className="q-field__bottom row align-items-start q-field__bottom--animated">
                    <div className="q-field__messages col">
                        {isRequired && (isWrong || error) ? (
                            <div role="alert">Обязательное поле</div>
                        ) : (
                            <div>{bottomLabel}</div>
                        )}
                    </div>
                </div>
            </div>
        </label>
    );
};

export default CustomInput;

import React, { useState, useEffect } from 'react';
import eventBus from '../../eventBus';

function CartItem({ uniqueId, product_id, imageUrl, name, price, quantity, extras, onRemove, onUpdateQuantity, onRemoveExtra, onUpdateExtraQuantity }) {
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const [localExtras, setLocalExtras] = useState(extras);
  const [localItemId, setLocalItemId] = useState(uniqueId);

  const handleRemove = () => {
    onRemove(localItemId);
  };

  const handleDecrease = () => {
    if (localQuantity > 1) {
      onUpdateQuantity(localItemId, localQuantity - 1);
    }
  };

  const handleIncrease = () => {
    onUpdateQuantity(localItemId, localQuantity + 1);
  };

  const handleRemoveExtra = (extraId) => {
    onRemoveExtra(localItemId, extraId);
  };

  const handleDecreaseExtra = (extraId) => {
    var extraCount = localExtras.find((extra) => extra.id === extraId).count;

    if (extraCount > 1) {
      onUpdateExtraQuantity(localItemId, extraId, extraCount - 1);
    }
  };

  const handleIncreaseExtra = (extraId) => {
    onUpdateExtraQuantity(localItemId, extraId, localExtras.find((extra) => extra.id === extraId).count + 1);
  };

  const handleCartItemQuantityUpdated = (data) => {
    if (data.uniqueId && data.localQuantity !== undefined) {
      if (data.uniqueId === localItemId) {
        setLocalQuantity(data.localQuantity);
      }
    }
  };

  useEffect(() => {
    setLocalQuantity(quantity);
    setLocalExtras(extras);
    eventBus.subscribe('cartItemQuantityUpdated', handleCartItemQuantityUpdated);
    setLocalItemId(uniqueId);
  }, [quantity, extras]);

  return (
    <div className="q-item q-item-type row no-wrap">
      <div className="q-item__section q-item__section--top justify-start col-2 p-0">
        <img src={imageUrl} alt="Food" />
      </div>
      <div className="q-item__section q-item__section--top justify-start col-8">
        <div className="q-item__label">
          <span>{name}</span>
          <div className="cart_item_extras" style={{ paddingTop: '6px' }}>
            {localExtras.map((extra, index) => (
              <div key={index} className="q-item__label q-item__label--caption text-caption mb-2">
                <span>Добавка: </span><br />
                <span> &nbsp; — {extra.name} </span>
                <span>{extra.price !== null ? ` ${extra.price} ₽` : ' Бесплатно'}</span>
                <button
                  className="q-btn q-btn-item non-selectable no-outline q-btn--unelevated q-btn--rectangle q-btn--rounded q-btn--actionable q-focusable q-hoverable"
                  tabIndex="0" type="button" style={{ fontSize: '10px' }}
                  onClick={() => handleRemoveExtra(extra.id)}
                >
                  <span className="q-focus-helper"></span>
                  <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                    <i className="fas fa-trash-alt"></i>
                  </span>
                </button>
                <div>
                  <button
                    className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--rounded q-btn--actionable q-focusable q-hoverable"
                    tabIndex="0" type="button" style={{ fontSize: '10px' }}
                    onClick={() => handleDecreaseExtra(extra.id)}
                  >
                    <span className="q-focus-helper" tabIndex="-1"></span>
                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </span>
                  </button>
                  <span>&nbsp; {extra.count} &nbsp;</span>
                  <button
                    className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--rounded q-btn--actionable q-focusable q-hoverable"
                    tabIndex="0" type="button" style={{ fontSize: '10px' }}
                    onClick={() => handleIncreaseExtra(extra.id)}
                  >
                    <span className="q-focus-helper" tabIndex="-1"></span>
                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button
            className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
            onClick={handleDecrease}
            tabIndex="0"
            type="button"
          >
            <span className="q-focus-helper"></span>
            <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
              <i className="fa fa-minus" aria-hidden="true"></i>
            </span>
          </button>
          <span>&nbsp; {localQuantity} &nbsp;</span>
          <button
            className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
            onClick={handleIncrease}
            tabIndex="0"
            type="button"
          >
            <span className="q-focus-helper"></span>
            <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
      <div className="q-item__section column q-item__section--top justify-start col-2 p-0">
        <div className="q-item__label">{price} ₽</div>
        <button className="q-btn q-btn-item q-btn--unelevated q-btn--rounded q-hoverable"
          onClick={handleRemove}
          tabIndex="0" type="button">
          <span className="q-focus-helper"></span>
          <span className="col align-items-center justify-content-center row">
            <i className="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>
    </div>
  );
}

export default CartItem;

import React, { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { useCity } from '../contexts/CityContext';
import eventBus from '../eventBus';

function PaymentConfirmPage() {
  const city = useCity();

  const clearCart = () => {
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartRestaurantInfo');
    localStorage.removeItem('cartShopInfo');
    localStorage.removeItem('deliveryMode');

    eventBus.publish('cartCleared');
  };

  useEffect(() => {
    clearCart();
  }, []);


  return (
    <Layout city={city}>
      <section className="section_order">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <i className="fas fa-check-circle q-icon text-deep-orange" style={{ fontSize: '200px' }} aria-hidden="true"></i>
            </div>
            <div className="col-12 text-center">
              <h3>Ваш заказ принят!</h3>
              <h6>Ожидайте звонка от ресторана для подтверждения</h6>
              <div className="section-shop__info-back q-pt-md">
                <a href={`/City-${city}/`} className="router-link-active">
                  <i className="fas fa-chevron-left"></i> На главную страницу
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PaymentConfirmPage;

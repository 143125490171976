import React from 'react';

const CategoryItem = ({ id, description, media, name, sort, isActive, onClick }) => {
  const classNames = `section-shop__categories-item col-12 col-sm-2 col-md-1 ${isActive ? 'section-shop__categories-item-active' : ''}`;

  return (
    <li className={classNames} onClick={() => onClick(id)}>
      <div className="row">
        <div className="col-xs-5 col-sm-12 col-md-12">
          <img src={media?.icon} alt="" className="section-shop__categories-img" />
        </div>
        <div className="col-xs-5 col-sm-12 col-md-12 d-flex align-items-center justify-content-center">
          <p className="section-shop__categories-text">{name}</p>
        </div>
      </div>
    </li>
  );
};

export default CategoryItem;

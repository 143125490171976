import { parentApi } from '../../fetcher';

export const shopsApi = parentApi.injectEndpoints({
    endpoints: (builder) => ({
        getShops: builder.query({
            query: (city) => `${city}/shops?searchJoin=and`,
            providesTags: ['RESTAURANTS'],
        }),
        getShopByName: builder.query({
            query: (params) => {
                const { city, shopName } = params;
                const filterFields = 'id;name;available_for_delivery;minimum_sum_of_delivery_free;closed;delivery_fee;week_schedule;work_time_start;work_time_end'

                return `${city}/shops?search=${shopName}&searchFields=name&filter=${filterFields}`;
            },
            providesTags: ['RESTAURANT'],
        }),
        getShopCategories: builder.query({
            query: (params) => {
                const { city, shop_id } = params;
                return {
                    url: `${city}/siteCategoryProduct`,
                    method: 'POST',
                    body: { shop_id: shop_id },
                };
            },
            providesTags: ['CATEGORIES'],
        }),
        getShopCategoryProducts: builder.query({
            query: (params) => {
                const { city, category_id, shop_id } = params;
                return {
                    url: `${city}/siteShopProductCategory?category_id=${category_id}&shop_id=${shop_id}`,
                    method: 'POST',
                    body: { 
                        category_id: category_id, 
                        shop_id: shop_id 
                    },
                };
            },
            providesTags: ['CATEGORIES'],
        }),
    }),
});

export const { useGetShopsQuery, useGetShopByNameQuery, useGetShopCategoriesQuery, useGetShopCategoryProductsQuery } = shopsApi;
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const WithCityParam = ({ children }) => {
  const { city } = useParams();

  if (!city || !city.startsWith('City-')) {
    return <Navigate to="/404" />;
  }

  const cityValue = city.replace('City-', '');

  return children({ city: cityValue });
};

export default WithCityParam;

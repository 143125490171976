import React, { useState, useEffect, useRef } from 'react';
import { useCity } from '../contexts/CityContext';
import Layout from '../components/layout/Layout';
import Slider from '../components/slider/Slider';
import RestaurantList from '../components/lists/RestaurantList';
import ShopList from '../components/lists/ShopList';
import ShopSlider from '../components/slider/ShopSlider';

function CityPage() {
  const city = useCity();
  const sectionRef = useRef();
  const [isMobile, setIsMobile] = useState(false);

  const handleScrollToRestaurants = () => {
    const sectionTop = sectionRef.current.offsetTop - 180;

    window.scrollTo({
      top: sectionTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  return (
    <Layout city={city}>
      <section className="section-banner">
        <div className="section-banner__container">
          <img src="/assets/images/maket11.jpg" alt="Restaurnat banner" className="section-banner__img d-none d-sm-block" />
          <img src="/assets/images/restaurnat-banner-phone.jpg" alt="Restaurnat banner" className="section-banner__img d-sm-none" />
          <div className="section-banner__block-header">
            <h1 className="main-headin">ДОСТАВКА ГОТОВЫХ БЛЮД ИЗ РЕСТОРАНОВ</h1>
            <button className="section-banner__block-btn d-flex float-start no-outline border-0" onClick={handleScrollToRestaurants}>
              <span className="section-banner__btn cursor-pointer">Заказать доставку</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section-swiper">
        <div className="container section-swiper__container">
          <Slider city={city} />
        </div>
      </section>
      <section className="section-info d-none d-sm-block">
        <div className="section-info__container">
          <div className="section-info__img">
            <div className="section-info__block row justify-content-between">
              <div className="section-info__block-text text-start justify-content-center col-7 col-sm-4 col-md-4 col-lg-4">
                <div className="row">
                  <div className="col-3">
                    <img src="/assets/images/1.png" alt="1" className="section-info__block-text-img" />
                  </div>
                  <div className="col-9 d-flex align-content-center">
                    <h2 className="section-info__block-heading">Выбери ресторан</h2>
                  </div>
                </div>
              </div>
              <div className="section-info__block-text text-start justify-content-center col-7 col-sm-4 col-md-4 col-lg-4">
                <div className="row">
                  <div className="col-3">
                    <img src="/assets/images/2.png" alt="" className="section-info__block-text-img" />
                  </div>
                  <div className="col-9 d-flex align-content-center">
                    <h2 className="section-info__block-heading">Добавь в корзину</h2>
                  </div>
                </div>
              </div>
              <div className="section-info__block-text text-start justify-content-center col-7 col-sm-4 col-md-4 col-lg-4">
                <div className="row">
                  <div className="col-3 d-flex text-center">
                    <img src="/assets/images/3.png" alt="" className="section-info__block-text-img" />
                  </div>
                  <div className="col-9 d-flex align-content-center">
                    <h2 className="section-info__block-heading">Оформи заказ</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-restaurants" id="section-shops" ref={sectionRef}>
        <div className="section-restaurants__container">
          <h2 id="restaurants" className="section_restaurants_header_name">Магазины</h2>
          {isMobile ? <ShopSlider city={city} /> : <ShopList city={city} />}
        </div>
      </section>
      <section className="section-restaurants" id="section-restaurants">
        <div className="section-restaurants__container">
          <h2 id="restaurants" className="section_restaurants_header_name">Рестораны</h2>
          <RestaurantList city={city} />
        </div>
      </section>
    </Layout>
  );
}

export default CityPage;

import React, { useState, useEffect } from 'react';
import { getCityData } from '../../utils/city-data';
import AlertModal from '../modal/AlertModal';
import QRModal from '../modal/QRModal';
import { appVersion } from '../../version';

const Footer = ({ city }) => {
    const cityData = getCityData(city);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const [modalData, setModalData] = useState(null);
    const [alertModalData, setAlertModalData] = useState(null);

    const defaultIcons = {
        android: { icon: '/assets/images/playstore.png', url: 'https://play.google.com/store/apps/details?id=com.sunpanda.android' },
        ios: { icon: '/assets/images/applestore.png', url: 'https://apps.apple.com/us/app/sunpanda/id1556527898' },
        ok: { icon: '/assets/images/ok.png', url: 'https://ok.ru/profile/583908776890' },
        youtube: { icon: '/assets/images/yt.png', url: 'https://www.youtube.com/channel/UCCmwvQNIr3CciT5SvCW8fUg' },
        rutube: { icon: '/assets/images/rutube.png', url: '' },
        telegram: { icon: '/assets/images/tg.png', url: '' },
        vk: { icon: '/assets/images/vk.png', url: '' },
    };

    const generateSocialIcons = () => {
        const socialIcons = [];

        socialIcons.push(
            <a key="ok" href={cityData.social.Ok ? cityData.social.Ok : defaultIcons.ok.url} target="_blank" rel="noopener noreferrer">
                <img src={defaultIcons.ok.icon} alt="OK" className="footer__img-network"></img>
            </a>
        );

        if (cityData.social && cityData.social.Vk) {
            socialIcons.push(
                <a key="Vk" href={cityData.social.VkUrl} target="_blank" rel="noopener noreferrer">
                    <img src={defaultIcons.vk.icon} alt="VK" className="footer__img-network"></img>
                </a>
            );
        }

        if (cityData.social && cityData.social.Rutube) {
            socialIcons.push(
                <a key="Rutube" href={cityData.social.RutubeUrl} target="_blank" rel="noopener noreferrer">
                    <img src={defaultIcons.rutube.icon} alt="Rutube" className="footer__img-network"></img>
                </a>
            );
        }

        if (cityData.social && cityData.social.Tg) {
            socialIcons.push(
                <a key="Tg" href={cityData.social.TgUrl} target="_blank" rel="noopener noreferrer">
                    <img src={defaultIcons.telegram.icon} alt="Telegram" className="footer__img-network"></img>
                </a>
            );
        }

        socialIcons.push(
            <a key="youtube" href={defaultIcons.youtube.url} target="_blank" rel="noopener noreferrer">
                <img src={defaultIcons.youtube.icon} alt="YouTube" className="footer__img-network"></img>
            </a>
        );

        return socialIcons;
    };

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setShowScrollButton(scrollTop > 0);
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleUpdateButtonClick = () => {
        localStorage.clear();

        localStorage.setItem('appVersion', appVersion);

        setAlertModalData(null);
        window.location.reload(true);
    };

    const showQRModal = () => {
        const excludedPaths = ['/order', '/payment-confirm'];
        const currentPath = window.location.pathname;

        if (!excludedPaths.some(excludedPath => currentPath.includes(excludedPath))) {
            setModalData(null);
            return;
        } else {
            setModalData(cityData);
        }
    };

    const checkForUpdate = async () => {
        try {
            const serverVersion = appVersion;
            const localVersion = localStorage.getItem('appVersion');

            if (!localVersion || serverVersion > localVersion) {
                setAlertModalData({
                    title: 'Уведомление!',
                    message: 'SunPanda приветствует Вас',
                    onClose: handleUpdateButtonClick,
                });
            }
        } catch (error) {
            console.error('Ошибка при проверке версии:', error);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        const timeoutId = setTimeout(() => {
            showQRModal();
        }, 30000);

        checkForUpdate();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timeoutId);
        };
    }, [cityData]);

    return (
        <footer className="footer">
            <div className="container footer-container row">
                <div id="footer__block_first" className="footer__block col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <img
                        src="/assets/images/logo-footer.png"
                        alt="Logo"
                        className="footer__logo"
                    />
                    <ul>
                        <li className="footer__item">
                            <a className="footer__link" href="https://franshiza.ru/franchise/read/SunPanda/" target="_blank" rel="noreferrer">
                                Франшиза SunPanda
                            </a>
                        </li>
                        <li className="footer__item">
                            <a href="/about" className="footer__link">
                                О нас
                            </a>
                        </li>
                        <li className="footer__item">
                            <a href="/partners" className="footer__link">
                                Стать партнером
                            </a>
                        </li>
                        <li className="footer__item">
                            <a href="/agreement" className="footer__link">
                                Пользовательское соглашение
                            </a>
                        </li>
                        {/* <li className="footer__item">
                            <a href="/privacy-policy" className="footer__link">
                                Конфиденциальность
                            </a>
                        </li> */}
                    </ul>
                </div>

                {city && cityData && (
                    <>
                        {/* Блок "Мы в соцсетях" */}
                        <div className="footer__block text-center col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <h2 className="second-heading footer__second-heading">Мы в соцсетях</h2>
                            {generateSocialIcons()}
                        </div>

                        {/* Блок "Скачай на телефон" */}
                        <div className="footer__block text-center col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <h2 className="second-heading footer__second-heading">Скачай на телефон</h2>
                            <div className="footer__mobile justify-content-end">
                                <a href={cityData.social?.iosurl || defaultIcons.ios.url} target="_blank" className="footer__mobile-link" rel="noopener noreferrer">
                                    <img src={defaultIcons.ios.icon} alt="Apple Store" className="footer__mobile-link-img"></img>
                                </a>
                                <a href={cityData.social?.androidurl || defaultIcons.android.url} target="_blank" className="footer__mobile-link" rel="noopener noreferrer">
                                    <img src={defaultIcons.android.icon} alt="Play Store" className="footer__mobile-link-img"></img>
                                </a>
                            </div>
                        </div>
                    </>
                )}

                <div className="footer__block text-center col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <h2 className="second-heading footer__second-heading footer__second-heading-contacts">
                        Контакты "Сан Панда"
                    </h2>
                    <p className="footer__tel">
                        Call центр: <a className="footer__tel" href="tel:+79962757918">8 (996) 275 79 18</a>
                    </p>
                    <p className="footer__tel">
                        E-mail: <a className="footer__tel" href="mailto:admin@sunpanda.ru">admin@sunpanda.ru</a>
                    </p>
                    <p className="footer__tel">© Copyright 2023 Все права защищены - sunpanda.ru</p>
                </div>
            </div>

            <div className="q-page-scroller">
                <div
                    className={`q-page-sticky row flex-center fixed-bottom-right q-page-sticky--shrink ${showScrollButton ? '' : 'hidden'}`}
                    onClick={handleScrollToTop}
                >
                    <div>
                        <button
                            className="q-btn non-selectable no-outline q-btn--rounded bg-deep-orange text-white"
                            tabIndex="0"
                            type="button"
                        >
                            <span className="col align-items-center justify-content-center row">
                                <i className="fas fa-chevron-up q-icon" aria-hidden="true" role="img"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {modalData && <QRModal cityData={modalData} onClose={() => setModalData(null)} />}
            {alertModalData && <AlertModal title={alertModalData.title} message={alertModalData.message} onClose={alertModalData.onClose} />}
        </footer>
    );
};

export default Footer;

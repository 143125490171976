import { useEffect } from 'react';
import { subscribeToChannel } from '../services/PusherService';

const NotificationComponent = () => {
  useEffect(() => {
    const unsubscribe = subscribeToChannel('order-payment-notification', 'PaymentNotification', (data) => {
      handlePaymentNotification(data);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handlePaymentNotification = (data) => {
    const storedData = JSON.parse(sessionStorage.getItem('orderData'));

    if (storedData && data.userId === storedData.userId && data.orderId === storedData.orderId) {
      if (data.success) {
        window.location.href = `${window.location.origin}/City-${storedData.orderCity}/payment-confirm`;
      } else {
        window.location.href = `${window.location.origin}/City-${storedData.orderCity}/payment-fail`;
      }
    }
  };

  return null;
};

export default NotificationComponent;

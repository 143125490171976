import React, { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

const QRModal = ({ cityData, onClose }) => {
    const [modal, setModal] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const modalElement = document.getElementById('qrmodal');
        const modalInstance = new Modal(modalElement);

        setModal(modalInstance);

        // Определение типа устройства
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);

        return () => {
            modalInstance.hide();
        };
    }, []);

    useEffect(() => {
        if (modal) {
            modal.toggle();
        }
    }, [modal]);

    const closeModal = () => {
        onClose();
    };

    return (
        <div className="modal fade" id="qrmodal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <div className="q-card text-center modal-card-wrapper">
                            <div className="q-card__section q-card__section--vert mb-3">
                                <img src="/assets/images/logo.jpg" alt="лого" className="header__item-logo-img" />
                            </div>
                            <div className="modal-text-wrapper">
                                <div>Скачай мобильное приложение</div>
                            </div>
                            <div className="q-card__section q-card__section--vert">
                                {isMobile ? (
                                    <img src="/assets/images/appimg.png" alt="App screens" className="modal-app-img"></img>
                                ) : (
                                    <img src={cityData.appQR} alt="QR-код" className="modal-qr-code" />
                                )}
                            </div>
                            <div className="modal-text-wrapper">
                                <div className="mb-4">Все рестораны города в Вашем телефоне</div>
                            </div>
                            {isMobile && (
                                isMobile && /iPhone|iPad|iPod/i.test(navigator.userAgent) ? (
                                    <a className="modal-download__button" href={cityData.social?.iosurl} target="_blanc">СКАЧАТЬ</a>
                                ) : (
                                    <a className="modal-download__button" href={cityData.social?.androidurl} target="_blanc">СКАЧАТЬ</a>
                                )
                            )}
                            <div className="modal-close-button" onClick={closeModal} data-bs-dismiss="modal"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QRModal;

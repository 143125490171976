import React from 'react';

function NotFoundPage() {
  return (
    <div className="fullscreen bg-blue text-white text-center q-pa-md d-flex justify-content-center align-items-center">
      <div style={{ fontSize: '30vh' }}>404</div>
      <div className="text-h2" style={{ opacity: 0.4 }}>Oops. Nothing here...</div>
      <a className="btn btn-light mt-5" href="/"><span>Go Home</span></a>
    </div>
  );
}

export default NotFoundPage;

import React from 'react';
import Layout from '../components/layout/Layout';

function AgreementPage() {
  return (
    <Layout>
      <section className="section-agreement">
        <div className="container">
          <h1>Пользовательское соглашение</h1> 1. Термины и определения 1.1. В целях настоящего документа нижеприведенные
          термины используются в следующем значении: Приложение SunPanda — программа для мобильных устройств,
          предоставляемая Пользователям на условиях пользовательского соглашения на использование программы SunPanda для
          мобильных устройств, выложенного в открытом доступе в сети Интернет по адресу:
          https://play.google.com/store/apps/details?id=com.sunpanda.android ;
          https://apps.apple.com/us/app/sunpanda/id1556527898. Сервис SunPanda, Сервис — означает все веб-сайты (включая,
          но не ограничиваясь, размещенными в сети Интернет по адресу: https://apps.apple.com/us/app/sunpanda/id1556527898
          , https://play.google.com/store/apps/details?id=com.sunpanda.android ), программы для ЭВМ (в том числе
          Приложение SunPanda), предоставляющие Пользователю Сервиса возможность осуществить Заказ Товара и Доставки на
          условиях, предусмотренных в Пользовательском соглашении. Информация о Ресторане (-ах), размещающих свои
          предложения о реализации Товаров, доступна в интерфейсе SunPanda. Заказ Пользователем Товаров и Доставки из
          раздела SunPanda осуществляется на условиях настоящего Пользовательского соглашения. Пользователь — лицо,
          использующее Сервис на условиях, предусмотренных в Пользовательском соглашении, с целью осуществить Заказ Товара
          и Доставки или только Товара с условием самовывоза из Ресторана. Авторизованный пользователь — Пользователь,
          авторизовавшийся на Сервисе с помощью логина и пароля, присвоенных ему при регистрации на Сервисе. Ресторан —
          юридическое лицо или индивидуальный предприниматель, осуществляющее (ий) деятельность по продаже Товара и/или
          приготовление Товара Пользователям Сервиса при оформлении ими Заказа, а также осуществляющее(ий) Доставку Товара
          Пользователям Сервиса в случае, когда соответствующая возможность предусмотрена на Сервисе; Заказ — оформленный
          Пользователем на Сервисе заказ Товара и Доставки или только Товара, в результате которого Пользователь заключает
          договор о приготовлении и/или реализации Товара с Рестораном, и договор доставки данного Товара Доставки
          Рестораном, — с Рестораном на условиях, определенных Рестораном. Договор — договор реализации (купли-продажи)
          и/или о приготовлении Товара с Рестораном, и/или в случае осуществления Доставки Рестораном, — с Рестораном,
          заключаемый между Пользователем и Рестораном в результате оформления Заказа. Товар — товары, являющиеся
          предметом договора купли-продажи и/или договора на приготовление, заключаемого между Пользователем и Рестораном
          в результате оформления Пользователем Заказа на Сервисе. При упоминании в Пользовательском соглашении или иных
          документах, указанных в п. 2.2 Пользовательского соглашения. Все взаимоотношения, связанные с реализацией
          (куплей-продажей) и/или приготовлением Товаров, в результате оформления Пользователем Заказа на Сервисе,
          возникают непосредственно между Рестораном и Пользователем; Доставка — услуга доставки Товара, Заказ которого
          Пользователь оформил на Сервисе, до Пользователя. Заключение с Пользователем договора о доставке осуществляет
          Ресторан, посредством оформления Пользователем Заказа на Сервисе. Ресторан имеют право привлекать третьих лиц
          для осуществления доставки Товара до Пользователя; Промокод — определенная последовательность символов, при
          условии активации которой и соблюдении иных условий использования Промокода Пользователю предоставляется скидка
          на стоимость Товара и/или Доставки. 1.2. В Пользовательском соглашении могут быть использованы термины, не
          определенные в п. Пользовательского соглашения. В этом случае толкование такого термина производится в
          соответствии с текстом Пользовательского соглашения. В случае отсутствия однозначного толкования термина в
          тексте Пользовательского соглашения следует руководствоваться толкованием термина, определенным: в первую
          очередь — законодательством РФ, во вторую очередь — на Сервисе, затем — сложившимся (общеупотребимым) в сети
          Интернет. 2.1. Настоящее Пользовательское соглашение представляет собой предложение Сервиса Пользователю
          использовать Сервис SunPanda. 2.3. Начиная использовать Сервис/его отдельные функции, Пользователь считается
          принявшим Пользовательское соглашение, а также условия Регулирующих документов, в полном объеме, без всяких
          оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Пользовательского соглашения
          и/или Регулирующих документов, Пользователь не вправе использовать Сервис. 2.4. Сервис вправе в любое время без
          уведомления Пользователя изменить положения Пользовательского соглашения, вправе в любое время без уведомления
          Пользователя изменить условия Регулирующих документов. Действующая редакция Пользовательского соглашения
          размещается по адресу, указанному в п. 1.1 Пользовательского соглашения. Риск не ознакомления с новой редакцией
          Пользовательского соглашения и Регулирующих документов несет Пользователь, продолжение пользования Сервисом
          после изменения Пользовательского соглашения или Регулирующих документов считается согласием с их новой
          редакцией. 2.5.В случае если Сервисом были внесены какие-либо изменения в Пользовательское соглашение, в
          порядке, предусмотренном п. 2.4. Пользовательского соглашения, с которыми Пользователь не согласен, он обязан
          прекратить использование Сервиса. 2.6. Сервис предлагает Пользователю бесплатную возможность осуществить Заказ
          Товара и Доставки у Ресторана и/или Доставщика на условиях, определенных Рестораном и/или Доставщиком и
          размещенных на Сервисе. Все существующие на данный момент функции Сервиса, а также любое развитие их и/или
          добавление новых являются предметом Пользовательского соглашения. 2.7. В зависимости от региона Пользователя,
          адреса Доставки, информации о Товаре, предоставляемой Рестораном, а также от иных обстоятельств все или
          некоторые функции Сервиса могут быть недоступны или ограничены. Функции считаются недоступными (ограниченными)
          для Пользователя, если Пользователь не может ими воспользоваться в явном виде. Применение любых технических и
          программных методов обхода данных ограничений запрещено. Положения Пользовательского соглашения, регулирующие
          недоступные (ограниченные) для Пользователя функции Сервиса, не применяются до тех пор, пока Пользователю такие
          функции не станут доступны в явном виде. Информация о доступности функций Сервиса предоставляется Пользователю
          по его запросу, направленному на Сервис. 2.8. Используя Сервис, Пользователь дает свое согласие на получение
          сообщений информационного и рекламного характера от Сервиса SunPanda. Пользователь вправе отказаться от
          получения сообщений рекламного характера путем использования соответствующего функционала Сервиса или следуя
          инструкциям, указанным в полученном сообщении рекламного характера. 2.9. Сервис не является уполномоченной
          организацией по смыслу Закона РФ от 07.02.1992 г. № 2300-1 «О защите прав потребителей», и не осуществляет
          рассмотрение и удовлетворение претензий Пользователей в отношении Товара и/или Доставки ненадлежащего качества,
          Заказ которого (которых) оформлен Пользователем на Сервисе. При обращении Пользователя в течение 7 (семи) дней
          после получения Товара на Сервис по вопросам, касающимся Договора, заключаемого в результате оформления Заказа,
          в том числе с претензиями относительно исполнения данного Договора и/или с требованием о возврате Товара, Сервис
          вправе передать соответствующую информацию Ресторану и/или Доставщику, а также передать Пользователю информацию,
          полученную от Ресторану и/или Доставщику по данным вопросам. Обращения Пользователей по указанным в настоящем
          пункте вопросам по истечении 7 (семи) дней после получения Товара Пользователем, не принимаются Сервисом, и
          должны направляться Пользователем напрямую Ресторану и/или Доставщику, с которым Пользователь заключил Договор в
          результате оформления Заказа. 2.11. Сервис не несет ответственность за содержание и/или актуальность информации,
          предоставляемой Рестораном и/или Доставщиком о Товаре и/или Доставке, включая информацию о стоимости Товара
          и/или Доставки, сроках приготовления Товара и/или осуществления Доставки, а также наличии Товара в данный
          момент. 2.13. Использование отдельных функций Сервиса, в том числе оформление Заказа, доступно Пользователю
          после прохождения регистрации и авторизации на Сервисе. При регистрации Пользователь обязуется предоставить
          данные о себе, запрашиваемые на Сервисе в форме регистрации, а также указать адрес электронной почты и номер
          телефона, который будет использоваться в качестве логина, и пароль. Пользователь обязуется не передавать логин и
          пароль, присвоенные ему при регистрации, третьим лицам, а также обеспечивать сохранность и конфиденциальность
          логина и пароля. 2.14. Пользователь не вправе использовать более одной учетной записи при использовании Сервиса.
          В случае выявления Сервисом обстоятельств, свидетельствующих о нарушении Пользователем указанного в настоящем
          пункте условия, Сервис праве применить меры, предусмотренные п. 4.1 Пользовательского соглашения. 3. Порядок и
          условия оформления Заказа на Сервисе 3.1. Оформление Заказа доступно только Авторизованному пользователю. До
          момента оформления Заказа Товара и Доставки на Сервисе Пользователь обязуется ознакомиться со всеми условиями
          приготовления и/или реализации Товара, а также условиями осуществления Доставки, определенными Рестораном и
          размещенными на Сервисе. При этом очевидные ошибки, включая опечатки, допущенные Рестораном в описании Товара,
          юридически обязывающими не являются. 3.2. Пользователь при оформлении Заказа заключает Договор с Рестораном, и
          вступает в прямые договорные отношения с Рестораном в части приготовления и/или реализации Товара и
          осуществления Доставки. Оформление Заказа на Сервисе означает согласие Пользователя со всеми существенными
          условиями приготовления и/или реализации Товара, осуществления Доставки Рестораном, определенными Рестораном и
          размещенными на Сервисе. 3.3. Существенные условия заключаемого Пользователем с Рестораном Договора указываются
          в опубликованном на Сервисе описании Товара, условий его приготовления и/или реализации, предоставленных
          Рестораном Сервисом, а также описании условий Доставки, предоставленных Рестораном. 3.4. Оформляя Заказ,
          Пользователь соглашается с тем, что Ресторан может поручить исполнение Договора третьему лицу, при этом
          оставаясь ответственным за его исполнение. 3.5. Все права и обязательства по заключаемому с Пользователем
          Договору (Договорам) возникают непосредственно у Ресторана. 3.6. Пользователь подтверждает свое согласие с тем,
          что персональная информация, которую Пользователь предоставляет Сервису при использовании Сервиса, в том числе
          при оформлении Заказа, может быть передана Ресторану, с которым Пользователь заключает Договор в результате
          оформления Заказа, для целей исполнения Рестораном указанного Договора с Пользователем. Пользователь соглашается
          с тем, что Сервис вправе хранить персональную информацию Пользователя, а также персональную информацию
          указанного Пользователем получателя Заказа не менее трех лет с даты исполнения соответствующего Заказа
          Рестораном. Пользователь, в случаях, когда применимо, гарантирует законность предоставления персональной
          информации лица, чьи данные Пользователь предоставляет, осведомленность и согласие такого лица на предоставление
          его персональной информации. 3.7. Для Авторизованного пользователя информация об оформленном им Заказе доступна
          в разделе «Мои Заказы» Сервиса. В данном разделе Сервиса Авторизованный пользователь может просмотреть
          информацию о статусе Заказа. 3.8. Оформляя заказ на Сервисе, Пользователь соглашается на получение электронных
          писем, которые Сервис может направить Пользователю: после оформления Заказа; в случае его изменения/отмены;
          перед Доставкой Товара в случае, влияющем на исполнение Заказа или своевременное информирование о его статусе,
          сообщений (SMS) на телефонный номер, указанный Пользователем, звонка от Сервиса/Ресторана в случае невозможности
          выполнения Рестораном Заказа в полном объеме для изменения/отмены Заказа; push-уведомлений о статусе Заказа; а
          также звонок с предложением оценить качество работы Сервиса, Ресторана на телефонный номер, указанный
          Пользователем. 3.9. Оформляя Заказ, Пользователь подтверждает, что а) полностью ознакомился с положениями
          Пользовательского соглашения и Регулирующих документов, б) полностью понимает Пользовательское соглашение и
          Регулирующие документы, а также предмет и условия заключаемого с Рес, в) полностью понимает значение и
          последствия своих действий в отношении заключения и исполнения Договора с Рестораном. 3.10. Сервис вправе
          заблокировать Пользователю возможность оформления Заказа в случае неисполнения обязательства по оплате Товара и
          Доставки в рамках оформленного Заказа, при отказе от исполнения Договора (Договоров) с Рестораном в соответствии
          с п. 3.18 Пользовательского соглашения, в случае нарушения Пользовательского соглашения и/или Регулирующих
          документов, а также в случае неоднократного нарушения Договоров с Рестораном и/или Доставщиком. 3.11.
          Пользователь обязан указать достоверные данные о себе и получателе Заказа при оформлении Заказа на Сервисе.
          Пользователь несет ответственность за все последствия, вызванные недостоверностью, предоставленной им
          информации. 3.12. Для оформления Заказа Пользователь, ознакомившись с информацией о Товаре и Доставке, указывает
          с помощью Сервиса все необходимые для исполнения Договора Рестораном и/или Доставщиком данные, включая, но не
          ограничиваясь: персональная информация Пользователя, полный адрес доставки (включая название населенного пункта,
          улицы (проспекта, шоссе, пр.), номер дома (включая корпус, строение, владение, прочее), номер подъезда, номер
          квартиры, этаж, код домофона (если применимо)), контактные данные (номер телефона, иные), способ оплаты, после
          чего нажимает кнопку «Отправить заказ» (иную аналогичную кнопку) на Сервисе. Оформляя Заказ, Пользователь
          подтверждает факт ознакомления и безоговорочного согласия с Пользовательским соглашением, условиями заключаемого
          Пользователем с Рестораном Договора/Договоров, а также факт ознакомления с информацией о Товаре и Доставке,
          размещенной на Сервисе. 3.13. Момент заключения Договора/Договоров между Пользователем и Рестораном определяется
          в следующем порядке: 3.13.1. в случае выбора Пользователем способа оплаты, предусмотренного пп. 5.1.1, 5.1.2
          Пользовательского соглашения — Договор/Договоры с Рестораном заключается в момент нажатия Пользователем кнопки
          «Заказать» в форме оформления Заказа. 3.13.2. в случае выбора Пользователем способа оплаты, предусмотренного пп.
          5.1.3 Пользовательского соглашения — Договор/Договоры с Рестораном заключается в момент нажатия Пользователем
          кнопки «Перейти к оплате» в форме оформления Заказа, Договор с Доставщиком заключается в момент определения
          Доставщика, осуществляющего Доставку Товара, в соответствии с п. 3.14 Пользовательского соглашения. После
          нажатия указанной кнопки Пользователю предоставляется ограниченное время на осуществление оплаты, по истечении
          которого будет выдаваться сообщение «ОШИБКА Время платежа (сессии) истекло. Оформите новый заказ». В случае если
          в указанный период времени по любой причине оплаты не произошло, Договор/Договоры с Рестораном не считается/ются
          заключенным/ми. 3.13.3. Нажатием кнопки «Заказать» в случае, предусмотренном в п. 3.13.1 Пользовательского
          соглашения, или «Перейти к оплате» в случае, предусмотренном в п. 3.13.2 Пользовательского соглашения,
          Пользователь выражает полное и безоговорочное согласие (акцепт) на заключение Договора с Договором. 3.14.
          Доставщик, выполняющий Доставку Товара, определяется из числа Доставщиков, предусмотренных п. 6.8
          Пользовательского соглашения, после оформления Пользователем Заказа в течение 5 минут с момента начала
          выполнения Рестораном Заказа (начала приготовления Рестораном Товара). Сервис уведомляет Пользователя о
          поступлении от Ресторана информации о начале выполнения Рестораном Заказа (начале приготовления Рестораном
          Товара) с помощью отправки Пользователю push-уведомления (при этом Сервис не несет ответственность за
          неполучение Пользователем push-уведомления в случае отключения Пользователем функции получения таких уведомлений
          от Приложения SunPanda в мобильном устройстве; риск неполучения соответствующей информации в указанном случае
          несет Пользователь) или SMS-сообщения при оформлении Заказа Пользователем с помощью Приложения SunPanda, путем
          отображения статуса Заказа «Заказ готовится». 3.15. В момент выполнения Пользователем действий, предусмотренных
          п. 3.12, Пользователь подтверждает, что вся предусмотренная действующим законодательством о защите прав
          потребителей информация о Товаре и Доставке, а также о Ресторане и/или Доставщике доведена до сведения
          Пользователя в полном объеме. 3.16. Ресторан может отменить Заказ в случае: 3.16.1. оформления Пользователем
          Заказа, доставку Товара в рамках которого осуществляет Ресторан, при выборе Пользователем оплаты наличными
          денежными средствами — если Ресторану не удалось связаться с Пользователем по контактным данным, предоставленным
          Пользователем при оформлении Заказа; 3.16.2. если Ресторан не может исполнить Договор. 3.17. В случае если
          Ресторан не может исполнить Договор в полном объеме, он вправе отменить заказ в соответствии с п. 3.16.2., либо
          связаться с Пользователем по указанному Пользователем номеру телефона с целью изменения состава Заказа.
          Информация об изменении Заказа также отражается на Сервисе с возможностью подтверждения Пользователем таких
          изменений. Заказ считается оформленным на новых условиях при согласовании Пользователем таких условий одним из
          следующих способов: а) по телефону; б) на Сервисе. 3.18. Пользователь, оформивший Заказ, обязан присутствовать
          по адресу Доставки для получения Заказа, а также обеспечить принятие и оплату Товара при его Доставке. В случае
          отсутствия Пользователя по адресу Доставки, а равно как в случае невозможности вручения Товара Пользователю по
          причинам, возникшим в результате действий/бездействия Пользователя, а равно как в случае отказа Пользователя от
          принятия Товара (за исключением Товара ненадлежащего качества или не соответствующего условиям оформленного
          Пользователем Заказа при соблюдении Пользователем п. 3.19 Пользовательского соглашения), по истечении 10 минут
          ожидания Пользователь считается отказавшимся от исполнения Договора (Договоров) с Рестораном и/или Доставщиком,
          при этом денежные средства, уплаченные за Товар и Доставку Пользователем, направляются на возмещение расходов
          Ресторана и/или Доставщика и не подлежат возврату Рестораном и/или Доставщиком. 3.19. В случае, если Товар,
          доставленный Пользователю, по мнению Пользователя является Товаром ненадлежащего качества Товара и/или не
          соответствует Заказу, оформленному Пользователем, Пользователь обязан незамедлительно направить на адрес
          электронной почты admin@sunpanda.ru фотографии доставленного Товара, а также направить описание недостатков
          Товара. Сервис принимает претензии от Пользователей для передачи их Ресторану в сроки, установленные в п. 2.10.
          3.20. В случае осуществления Доставки Товара Рестораном считается исполнившим свою обязанность по передаче
          Пользователю Товара по Договору в момент передачи Товара (надлежащего качества и соответствующего оформленному
          Пользователем Заказу) представителю Доставщика. 3.21. Время приготовления и/или реализации Товара и Доставки,
          указанное на Сервисе, является приблизительным. 4. Использование Сервиса. Отдельные функции Сервиса 4.1. Сервис
          оставляет за собой право на свое усмотрение ограничить доступ Пользователя к Сервису или к определенным функциям
          Сервиса с использованием его учетной записи или полностью заблокировать учетную запись Пользователя при
          нарушении Пользовательского соглашения и/или Регулирующих документов; грубом, невежливом, некорректном,
          создающем угрозу жизни, здоровью или имуществу и/или способном повлечь моральный вред поведении Пользователя в
          отношении сотрудников и/или представителей Доставщиков, Ресторанов и/или Магазинов, либо применить к
          Пользователю иные меры с целью соблюдения Пользовательского соглашения и Регулирующих документов, а также
          требований законодательства или прав и законных интересов третьих лиц. 4.3. В целях контроля качества и
          разрешения спорных вопросов Сервис может осуществлять запись всех разговоров Пользователя со службой поддержки
          Сервиса, а также Ресторанами. Оплата Товара и/или Доставки 5.1. Оплата Товара и/или Доставки в рамках
          оформленного Пользователем Заказа, может быть произведена Пользователем: 5.1.1. Непосредственно Ресторану
          наличными денежными средствами в случае осуществления Доставки Товара Рестораном. Указанный вид оплаты
          осуществляется без участия Сервиса и не регулируется Пользовательским соглашением. 5.1.3. Пользователю может
          быть доступна функция безналичной оплаты с Привязанной банковской карты (п. 5.4 Пользовательского соглашения); в
          этом случае Сервис действует по поручению Ресторана с привлечением уполномоченного оператора по приему платежей
          или оператора электронных денежных средств и является получателем платежа в качестве агента Ресторана и/или
          Доставщика (далее — «безналичная оплата»). Сервис не гарантирует отсутствие ошибок и сбоев в работе Сервиса в
          отношении предоставления возможности безналичной оплаты. Выбор соответствующей формы оплаты производится
          Пользователем в интерфейсе Сервиса. 5.1.4. Сервис не гарантирует отсутствие ошибок и сбоев в работе Сервиса в
          отношении предоставления возможности оплаты наличными или безналичными денежными средствами. Выбор
          соответствующей формы оплаты производится Пользователем в интерфейсе Сервиса. При этом, доступный Пользователю в
          конкретный момент времени способ оплаты Товара и/или Доставки определяется Сервисом с учетом технических,
          временных, материальных и/или иных факторов. 5.2. Прием денежных средств Сервисом в случае, предусмотренном п.
          5.1.3 Пользовательского соглашения, осуществляется исключительно в связи с тем, что Сервис посредством
          предоставления возможности оформления Заказа на Сервисе участвует в реализации Рестораном Пользователю Товаров,
          в оплату которых принимаются денежные средства. Сервис не являются платежными агентами при проведении расчетов в
          соответствии с Пользовательским соглашением согласно пп. 1, 4 ч. 2 ст. 1 Федерального закона от 03.06.2009 №
          103-ФЗ «О деятельности по приему платежей физических лиц, осуществляемой платежными агентами». 5.3. В случаях,
          предусмотренных действующим законодательством, при оплате Доставки и/или Товара способами, предусмотренными пп.
          5.1.2, 5.1.3 Пользовательского соглашения, кассовый чек направляется на адрес электронной почты, указанный
          Пользователем при регистрации на Сервисе или на номер телефона пользователя. 5.4. Привязанная банковская карта
          может указываться Пользователем в интерфейсе Сервиса, при этом Пользователь указывает следующие данные: •
          Наименование владельца банковской карты (значение «cardholder»); • Номер банковской карты; • Действительна до; •
          Защитный код. Если данные банковской карты верны, действительны и использование данной карты в рамках Сервиса
          технически возможно, указанная банковская карта приобретает статус Привязанной и может быть использована для
          безналичной оплаты. Все Привязанные карты отображаются в интерфейсе Сервиса. 5.5. Безналичная оплата
          осуществляется Пользователем с участием уполномоченного оператора по приему платежей или оператора электронных
          денежных средств и регулируется правилами международных платежных систем, банков (в том числе банка-эмитента
          Привязанной карты) и других участников расчетов. 5.6. При указании своих данных согласно п. 5.4
          Пользовательского соглашения и дальнейшем использовании Привязанной карты Пользователь подтверждает и
          гарантирует указание им достоверной и полной информации о действительной банковской карте, выданной на его имя;
          соблюдение им правил международных платежных систем и требований банка-эмитента, выпустившего Привязанную карту,
          в том числе в отношении порядка проведения безналичных расчетов. 5.7. Пользователь понимает и соглашается, что
          все действия, совершенные в рамках Сервиса после авторизации с помощью логина и пароля, присвоенных ему при
          регистрации на Сервисе, в том числе по безналичной оплате с использованием Привязанной банковской карты,
          считаются совершенными Пользователем. 5.8. В случае несогласия Пользователя с фактом и/или суммой безналичной
          оплаты и по иным связанным с использованием Привязанной карты в рамках Сервиса вопросам Пользователь вправе
          обратиться к Сервису по реквизитам, указанным в настоящем Пользовательском соглашении, в течение 14 дней со дня
          произведения безналичной оплаты или иных действий или событий, послуживших причиной обращения. В случае если по
          результатам проверки по такому обращению Сервис принимает решение о возврате суммы безналичной оплаты полностью
          или частично, указанный возврат осуществляется на банковский счет банковской карты, с которой была произведена
          безналичная оплата. Порядок возврата денежных средств осуществляется с участием уполномоченного оператора по
          приему платежей или оператора электронных денежных средств и регулируется правилами международных платежных
          систем, банков (в том числе банка-эмитента Привязанной карты) и других участников расчетов. 5.9. По вопросам,
          связанным с оплатой Товара и/или Доставки способами, предусмотренными пп. 5.1.1, 5.1.2 Пользовательского
          соглашения, Пользователь вправе обратиться к Ресторану и/или к Доставщику. 5.10. Сервис оставляет за собой право
          в любой момент потребовать от Пользователя подтверждения данных, указанных им в рамках Сервиса, в том числе
          данных Привязанной карты, и запросить в связи с этим подтверждающие документы (в частности, документы,
          удостоверяющие личность), непредставление которых, по усмотрению Сервисом, может быть приравнено к
          предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 4.1 Пользовательского
          соглашения. 6. Прочие условия 6.1. Пользователь подтверждает, что он является дееспособным и достиг возраста,
          необходимого в соответствии с законодательством Российской Федерации для заключения Договоров с Рестораном, а
          также для совершения иных сделок, предусмотренных Пользовательским соглашением. 6.2. Сервис не несет
          ответственность за: 6.2.1. Временные сбои и перерывы в работе Сервиса и/или доступность функционала,
          позволяющего Пользователям оформлять Заказ, и вызванные ими потери информации, а также сохранность информации,
          правильность и своевременность ее передачи и доставки. 6.2.2. Надежность, качество и скорость работы Сервиса, а
          также за сохранность создаваемой, используемой и получаемой Пользователем информации. 6.3. Порядок обращения
          Пользователя в Сервис: все обращения должны направляться на адрес электронной почты admin@sunpanda.ru, либо по
          адресу для переписки, указанному ниже. Функционалом Сервиса также могут быть предусмотрены иные способы
          направления Пользователем обращений на Сервис. 7. Информация о Сервисе SunPanda ИП Ермолаева Алина Алексеевна
          ИНН 302200695506 ОГРН 321784700059356 Юр адрес: г. Санк – Петербург пос. Шушары ул. Колпинское шоссе д. 34 корп.
          1 кв. 115
        </div>
      </section>
    </Layout>
  );
}

export default AgreementPage;

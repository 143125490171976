import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

export const authentication = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/auth",
});

export const parentApi = createApi({
    baseQuery: fetchBaseQuery({
        // baseUrl: process.env.REACT_APP_API_URL,
        baseUrl: "https://www.sunpanda.ru",
        prepareHeaders: async (headers) => {
            const token = localStorage.getItem("accessToken");
            token && headers.set("Authorization", "Bearer " + token);
            return headers;
        },
    }),
    refetchOnReconnect: true,
    tagTypes: ["SLIDES", "RESTAURANTS"],
    endpoints: () => ({}),
});

import { configureStore } from '@reduxjs/toolkit';
import { parentApi } from "../fetcher";
import { slidesApi } from "../modules/slides/api";

const store = configureStore({
  reducer: {
    [slidesApi.reducerPath]: slidesApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(parentApi.middleware),
});

export default store;

import React, { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

const AlertModal = ({ title, message, onClose }) => {
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const modalElement = document.getElementById('alertModal');
        const modalInstance = new Modal(modalElement);

        setModal(modalInstance);

        return () => {
            modalInstance.hide();
        };
    }, []);

    useEffect(() => {
        if (modal) {
            modal.toggle();
        }
    }, [modal]);

    const closeModal = () => {
        onClose();
    };

    return (
        <div className="modal fade" id="alertModal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <h5 className="modal-title">{title}</h5>
                        <p className='my-4'>{message}</p>
                        <button type="button" className="btn btn-primary text-white" onClick={closeModal} data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;

import React from 'react';
import Layout from '../components/layout/Layout';

function PrivacyPage() {
  return (
    <Layout>
      <section className="section-agreement">
        <div className="container">
          <h1>Политика конфиденциальности</h1>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}>
            <font>Политика в отношении
              обработки персональных данных</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>1.
            Общие положения</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>Настоящая
            политика обработки персональных данных
            составлена в соответствии с требованиями
            Федерального закона от 27.07.2006. №152-ФЗ «О
            персональных данных» и определяет
            порядок обработки персональных данных
            и меры по обеспечению безопасности
            персональных данных, предпринимаемые
            Индивидуальным Предпринимателем
            Ермолаевой Алиной Алексеевной (далее
            – Оператор).</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>1.1.
            Оператор ставит своей важнейшей целью
            и условием осуществления своей
            деятельности соблюдение прав и свобод
            человека и гражданина при обработке
            его персональных данных, в том числе
            защиты прав на неприкосновенность
            частной жизни, личную и семейную тайну.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>1.2.
            Настоящая политика Оператора в отношении
            обработки персональных данных (далее
            – Политика) применяется ко всей
            информации, которую Оператор может
            получить о посетителях мобильных
            приложений: </font><font><span lang="en-US">SunPanda</span></font><font>
              Ахтубинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Новошахтинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Каменск-Шахтинский, </font><font><span lang="en-US">SunPanda</span></font><font>
              Крымск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Сарапул, </font><font><span lang="en-US">SunPanda</span></font><font>
              Ейск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Симферополь, </font><font><span lang="en-US">SunPanda</span></font><font>
              Кропоткин, </font><font><span lang="en-US">SunPanda</span></font><font>
              Волгодонск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Челябинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Улан-Удэ, </font><font><span lang="en-US">SP</span></font><font>
              Админ, Кафе Пунш и на веб-сайте
              https://www.sunpanda.ru/.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.
            Основные понятия, используемые в Политике</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.1.
            Автоматизированная обработка персональных
            данных – обработка персональных данных
            с помощью средств вычислительной
            техники;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.2.
            Блокирование персональных данных –
            временное прекращение обработки
            персональных данных (за исключением
            случаев, если обработка необходима для
            уточнения персональных данных);</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.3.
            Информационная система персональных
            данных — совокупность содержащихся в
            базах данных персональных данных, и
            обеспечивающих их обработку информационных
            технологий и технических средств;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.4.
            Обезличивание персональных данных —
            действия, в результате которых невозможно
            определить без использования дополнительной
            информации принадлежность персональных
            данных конкретному Пользователю или
            иному субъекту персональных данных;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.5.
            Обработка персональных данных – любое
            действие (операция) или совокупность
            действий (операций), совершаемых с
            использованием средств автоматизации
            или без использования таких средств с
            персональными данными, включая сбор,
            запись, систематизацию, накопление,
            хранение, уточнение (обновление,
            изменение), извлечение, использование,
            передачу (распространение, предоставление,
            доступ), обезличивание, блокирование,
            удаление, уничтожение персональных
            данных;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.6.
            Оператор – государственный орган,
            муниципальный орган, юридическое или
            физическое лицо, самостоятельно или
            совместно с другими лицами организующие
            и (или) осуществляющие обработку
            персональных данных, а также определяющие
            цели обработки персональных данных,
            состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые
            с персональными данными;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.7.
            Персональные данные – любая информация,
            относящаяся прямо или косвенно к
            определенному или определяемому
            Пользователю мобильных приложений:
          </font><font><span lang="en-US">SunPanda</span></font><font>
              Ахтубинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Новошахтинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Каменск-Шахтинский, </font><font><span lang="en-US">SunPanda</span></font><font>
              Крымск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Сарапул, </font><font><span lang="en-US">SunPanda</span></font><font>
              Ейск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Симферополь, </font><font><span lang="en-US">SunPanda</span></font><font>
              Кропоткин, </font><font><span lang="en-US">SunPanda</span></font><font>
              Волгодонск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Челябинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Улан-Удэ, </font><font><span lang="en-US">SP</span></font><font>
              Админ, Кафе Пунш и на веб-сайте
              https://www.sunpanda.ru/;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.9.
            Пользователь – любой посетитель
            мобильных приложений: </font><font><span lang="en-US">SunPanda</span></font><font>
              Ахтубинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Новошахтинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Каменск-Шахтинский, </font><font><span lang="en-US">SunPanda</span></font><font>
              Крымск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Сарапул, </font><font><span lang="en-US">SunPanda</span></font><font>
              Ейск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Симферополь, </font><font><span lang="en-US">SunPanda</span></font><font>
              Кропоткин, </font><font><span lang="en-US">SunPanda</span></font><font>
              Волгодонск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Челябинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Улан-Удэ, </font><font><span lang="en-US">SP</span></font><font>
              Админ, Кафе Пунш и на веб-сайте
              https://www.sunpanda.ru/;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.10.
            Предоставление персональных данных –
            действия, направленные на раскрытие
            персональных данных определенному лицу
            или определенному кругу лиц;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.11.
            Распространение персональных данных
            – любые действия, направленные на
            раскрытие персональных данных
            неопределенному кругу лиц (передача
            персональных данных) или на ознакомление
            с персональными данными неограниченного
            круга лиц, в том числе обнародование
            персональных данных в средствах массовой
            информации, размещение в
            информационно-телекоммуникационных
            сетях или предоставление доступа к
            персональным данным каким-либо иным
            способом;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.12.
            Трансграничная передача персональных
            данных – передача персональных данных
            на территорию иностранного государства
            органу власти иностранного государства,
            иностранному физическому или иностранному
            юридическому лицу;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>2.13.
            Уничтожение персональных данных –
            любые действия, в результате которых
            персональные данные уничтожаются
            безвозвратно с невозможностью дальнейшего
            восстановления содержания персональных
            данных в информационной системе
            персональных данных и (или) уничтожаются
            материальные носители персональных
            данных.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.
            Оператор может обрабатывать следующие
            персональные данные Пользователя</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.1.
            Фамилия, имя, отчество;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.2.
            Электронный адрес;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.3.
            Номера телефонов;</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.4.
            Также на сайте происходит сбор и обработка
            обезличенных данных о посетителях (в
            т.ч. файлов «cookie») с помощью сервисов
            интернет-статистики (Яндекс Метрика и
            Гугл Аналитика и других).</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>3.5.
            Вышеперечисленные данные далее по
            тексту Политики объединены общим
            понятием Персональные данные.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>4.
            Цели обработки персональных данных</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>4.1.
            Цель обработки персональных данных
            Пользователя — предоставление доступа
            Пользователю к сервисам, информации
            и/или материалам, содержащимся на
            веб-сайте; Уведомление пользователей
            по средствам Push уведомлений и sms сообщений
            о новинках, акциях и других изменениях
            на сервисе.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>4.2.
            Также Оператор имеет право направлять
            Пользователю уведомления о новых
            продуктах и услугах, специальных
            предложениях и различных событиях.
            Пользователь всегда может отказаться
            от получения информационных сообщений,
            направив Оператору письмо на адрес
            электронной почты admin@sunpanda.ru с пометкой
            «Отказ от уведомлений о новых продуктах
            и услугах и специальных предложениях».</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>4.3.
            Обезличенные данные Пользователей,
            собираемые с помощью сервисов
            интернет-статистики, служат для сбора
            информации о действиях Пользователей
            на сайте, улучшения качества сайта и
            его содержания.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>5.
            Правовые основания обработки персональных
            данных</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>5.1.
            Оператор обрабатывает персональные
            данные Пользователя только в случае их
            заполнения и/или отправки Пользователем
            самостоятельно через специальные формы,
            расположенные в мобильных приложениях:
          </font><font><span lang="en-US">SunPanda</span></font><font>
              Ахтубинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Новошахтинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Каменск-Шахтинский, </font><font><span lang="en-US">SunPanda</span></font><font>
              Крымск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Сарапул, </font><font><span lang="en-US">SunPanda</span></font><font>
              Ейск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Симферополь, </font><font><span lang="en-US">SunPanda</span></font><font>
              Кропоткин, </font><font><span lang="en-US">SunPanda</span></font><font>
              Волгодонск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Челябинск, </font><font><span lang="en-US">SunPanda</span></font><font>
              Улан-Удэ, </font><font><span lang="en-US">SP</span></font><font>
              Админ, Кафе Пунш и на веб-сайте
              https://www.sunpanda.ru/. Заполняя соответствующие
              формы и/или отправляя свои персональные
              данные Оператору, Пользователь выражает
              свое согласие с данной Политикой.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>5.2.
            Оператор обрабатывает обезличенные
            данные о Пользователе в случае, если
            это разрешено в настройках браузера
            Пользователя (включено сохранение
            файлов «cookie» и использование технологии
            JavaScript).</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>6.
            Порядок сбора, хранения, передачи и
            других видов обработки персональных
            данных</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>Безопасность
            персональных данных, которые обрабатываются
            Оператором, обеспечивается путем
            реализации правовых, организационных
            и технических мер, необходимых для
            выполнения в полном объеме требований
            действующего законодательства в области
            защиты персональных данных.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>6.1.
            Оператор обеспечивает сохранность
            персональных данных и принимает все
            возможные меры, исключающие доступ к
            персональным данным неуполномоченных
            лиц.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>6.2.
            Персональные данные Пользователя
            никогда, ни при каких условиях не будут
            переданы третьим лицам, за исключением
            случаев, связанных с исполнением
            действующего законодательства.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>6.3.
            В случае выявления неточностей в
            персональных данных, Пользователь может
            актуализировать их самостоятельно,
            путем направления Оператору уведомление
            на адрес электронной почты Оператора
            admin@sunpanda.ru с пометкой «Актуализация
            персональных данных».</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>6.4.
            Срок обработки персональных данных
            является неограниченным. Пользователь
            может в любой момент отозвать свое
            согласие на обработку персональных
            данных, направив Оператору уведомление
            посредством электронной почты на
            электронный адрес Оператора
            admin@sunpanda.ru с пометкой «Отзыв согласия
            на обработку персональных данных».</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>7.
            Трансграничная передача персональных
            данных</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>7.1.
            Оператор до начала осуществления
            трансграничной передачи персональных
            данных обязан убедиться в том, что
            иностранным государством, на территорию
            которого предполагается осуществлять
            передачу персональных данных,
            обеспечивается надежная защита прав
            субъектов персональных данных.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>7.2.
            Трансграничная передача персональных
            данных на территории иностранных
            государств, не отвечающих вышеуказанным
            требованиям, может осуществляться
            только в случае наличия согласия в
            письменной форме субъекта персональных
            данных на трансграничную передачу его
            персональных данных и/или исполнения
            договора, стороной которого является
            субъект персональных данных.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>8.
            Заключительные положения</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>8.1.
            Пользователь может получить любые
            разъяснения по интересующим вопросам,
            касающимся обработки его персональных
            данных, обратившись к Оператору с помощью
            электронной почты admin@sunpanda.ru.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>8.2.
            В данном документе будут отражены любые
            изменения политики обработки персональных
            данных Оператором. Политика действует
            бессрочно до замены ее новой версией.</font></p>
          <p style={{ lineHeight: '108%', marginBottom: '0.11in' }}><font>8.3.
            Актуальная версия Политики в свободном
            доступе расположена в сети Интернет по
            адресу https://www.sunpanda.ru/privacy-policy.</font></p>
        </div>
      </section>
    </Layout>
  );
}

export default PrivacyPage;

import { parentApi } from '../../fetcher';

export const slidesApi = parentApi.injectEndpoints({
    endpoints: (builder) => ({
        getSlides: builder.query({
            query: (city) => `${city}/slides?filter=id;restaurant_id`,
            providesTags: ['SLIDES'],
        }),
    }),
});

export const { useGetSlidesQuery } = slidesApi;

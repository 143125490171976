import React from 'react';
import { useGetRestaurantsQuery } from '../../modules/restaurants/api';
import { getCityData } from '../../utils/city-data';
import RestaurantCard from '../cards/RestaurantCard';
import Spinner from '../spinner/Spinner';

const RestaurantList = ({ city }) => {
    const cityData = getCityData(city);
    const { data: restaurants, isLoading, isError } = useGetRestaurantsQuery(cityData.url);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <p>Error loading restaurants</p>;
    }

    return (
        <ul className="section-restaurants__list">
            {restaurants.data.map((restaurant, index) => (
                <RestaurantCard
                    key={index}
                    imageUrl={restaurant.media.length > 0 ? restaurant.media[0].url : ''}
                    link={`/City-${city}/shop-${restaurant.name}`}
                    deliveryPrice={restaurant.minimum_sum_of_delivery_free}
                    deliveryFee={restaurant.delivery_fee}
                    work_time_start={restaurant.work_time_start}
                    work_time_end={restaurant.work_time_end}
                    rating={parseFloat(restaurant.rate)}
                />
            ))}
            <li className="section-restaurants__item section-restaurants__item-empty">
                <div className="section-restaurants__block-text-empty">
                    <p className="section-restaurants__text-empty">СКОРО!!! <br />НОВЫЙ <br />РЕСТОРАН</p>
                </div>
            </li>
        </ul>
    );
};

export default RestaurantList;

import React from 'react';
import Layout from '../components/layout/Layout';

function AboutPage() {
  return (
    <Layout>
      <section className="section-about-banner">
        <div className="section-about-banner__container">
          <div className="section-about-banner__img">
            <div className="section-about-banner__text">
              <span className="section_about_header_name section-about-banner__main-headin">SunPanda</span>
              <h2 className="main-headin">АГРЕГАТОР ДОСТАВКИ ЕДЫ</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="section-about-application">
        <div className="section-about-application__container">
          <div className="section-about-application__block row">
            <div className="section-about-application__block-img section-about-application__block-img-mobile col-xs-12 col-sm-4">
            </div>
            <div className="section-about-application__block-img col-xs-12 col-sm-3">
              <div className="section-about-application__block-img-text">
                <h2 className="second-heading section-about-application__block-text">СДЕЛАЙ ЗАКАЗ</h2>
                <h3 className="section-about-application__block-text-one">На сайте</h3>
                <h3 className="section-about-application__block-text-two">В мобильном приложении</h3>
              </div>
            </div>
            <div className="section-about-application__block-img section-about-application__block-img-mac col-xs-12 col-sm-4">
            </div>
          </div>
        </div>
      </section>
      <section className="section-about-benefits">
        <h2 className="section-about-benefits__second-heading">НAШИ ПРЕИМУЩЕСТВА</h2>
        <div className="container row bi-align-center">
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Гарантия качества</h3>
            <p className="section-about-benefits__item-text">Мы сотрудничаем с лучшими заведениями Вашего города</p>
          </div>
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Никаких наценок</h3>
            <p className="section-about-benefits__item-text">Стоимость блюд, как в ресторанах, а иногда дешевле</p>
          </div>
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Просто и удобно</h3>
            <p className="section-about-benefits__item-text">Детально ознакомиться с меню и выбрать удобный способ доставки (до дверей квартиры, офиса или оформить самовывоз)</p>
          </div>
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Все заказы онлайн</h3>
            <p className="section-about-benefits__item-text">Оформить заказ онлайн (не созваниваясь с оператором)</p>
          </div>
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Выгодные предложения</h3>
            <p className="section-about-benefits__item-text">Акции, скидки, подарки от разных ресторанов в одном месте</p>
          </div>
          <div className="section-about-benefits__item col-xs-12 col-sm-6 col-md-6">
            <h3 className="section-about-benefits__item-heading">Всегда под контролем</h3>
            <p className="section-about-benefits__item-text">Отслеживание статуса Вашего заказа с помощью push-уведомлений</p>
          </div>
        </div>
      </section>
      <section className="section-call-center">
        <h2 className="section-call-center__second-heading">ВАМ НЕ ПРИВЕЗЛИ ЗАКАЗ?</h2>
        <div className="row justify-content-around">
          <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
            <div className="row section-call-center__block-item justify-content-center">
              <div className="col-3 d-flex align-items-center justify-content-end"><img src="/assets/images/1.png" alt="1" className="section-call-center__block-item-one" /></div>
              <div className="col-9 d-flex align-items-center">
                <p className="section-call-center__block-item-text">Позвоните в Call-центр SunPanda</p>
              </div>
            </div>
          </div>
          <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
            <div className="row section-call-center__block-item">
              <div className="col-3 d-flex align-items-center justify-content-end"><img src="/assets/images/2.png" alt="2" className="section-call-center__block-item-one" /></div>
              <div className="col-9 d-flex align-items-center">
                <p className="section-call-center__block-item-text">Назовите номер заказа</p>
              </div>
            </div>
          </div>
          <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
            <div className="row section-call-center__block-item">
              <div className="col-3 col-sm-3 d-flex align-items-center justify-content-end"><img src="/assets/images/3.png" alt="3" className="section-call-center__block-item-one" /></div>
              <div className="col-9 col-sm-9 d-flex align-items-center">
                <p className="section-call-center__block-item-text">Получите БЕСПЛАТНЫЙ купон на сумму Вашего заказа</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;

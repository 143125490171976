import React from 'react';
import Cart from '../cart/Cart';

const RightPanel = ({ isOpen, onClose, city }) => {
    return (
        <div className={`right-panel ${isOpen ? 'open' : ''}`}>
            <Cart city={city} />

            <div className="q-layout__shadow absolute-full overflow-hidden no-pointer-events"></div>

            <div className="panel-close-button" onClick={onClose}
                style={{ top: '4%', right: '95%', opacity: '1', zIndex: '1' }}>
                <button className="q-btn"
                    tabIndex="0"
                    type="button">
                    <span className="q-focus-helper" tabIndex="-1"></span>
                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                        <i className="fas fa-chevron-right"></i>
                    </span>
                </button>
            </div>
        </div>
    );
};

export default RightPanel;

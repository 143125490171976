import { useState, useEffect } from 'react';
import eventBus from '../eventBus';

const useCartTotalPrice = () => {
    const [total, setTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [couponTotal, setCouponTotal] = useState(0);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [deliveryMode, setDeliveryMode] = useState(null);

    const handleCartCleared = () => {
        setTotal(0);
        setTotalPrice(0);
        setDeliveryFee(0);
        setCouponTotal(0);
        setAppliedCoupon(null);
        setDeliveryMode(null);
    };

    const calculateItemPrice = (item) => {
        var extrasTotal = 0;

        if (item && item.extras) {
            extrasTotal = item.extras.reduce((total, extra) => total + (extra.price * (extra.count || 1) || 0), 0);
        }

        return (item.price + extrasTotal) * item.quantity;
    };

    const handleCartItemAdded = (data) => {
        setTotalPrice((prevTotalPrice) => prevTotalPrice + calculateItemPrice(data));
    };

    const handleCartItemRemoved = (data) => {
        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];

        if (!storedCart || storedCart.length === 0) {
            handleCartCleared();
            return;
        }

        setTotalPrice((prevTotalPrice) => {
            if (!data.quantity) data.quantity = 1;

            const itemPrice = calculateItemPrice(data);
            const newTotalPrice = (prevTotalPrice - itemPrice);

            return isNaN(newTotalPrice) ? 0 : newTotalPrice;
        });
    };

    const handleCartItemQuantityUpdated = (data) => {
        if ((data.food_id || data.product_id) && data.localQuantity !== undefined) {
            const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
            const initialTotalPrice = storedCart.reduce(
                (total, item) => total + calculateItemPrice(item),
                0
            );

            setTotalPrice(initialTotalPrice);
        }
    };

    const handleCouponApplied = (appliedCoupon) => {
        if (appliedCoupon) {
            setAppliedCoupon(appliedCoupon);
        } else {
            setAppliedCoupon(null);
        }
    };

    const handleDeliveryModeChanged = (mode) => {
        setDeliveryMode(mode);
    };

    const calculateDiscountedPrice = (currentTotal, coupon) => {
        if (!coupon || !coupon.discount_type || !coupon.discount) {
            return currentTotal;
        }

        const discountAmount = coupon.discount_type === 'percent' ?
            (currentTotal * coupon.discount) / 100 :
            Math.min(currentTotal, coupon.discount);

        return currentTotal - discountAmount;
    };

    const updateDeliveryFee = () => {
        const restaurantInfo = JSON.parse(localStorage.getItem('cartRestaurantInfo')) || [];
        const shopInfo = JSON.parse(localStorage.getItem('cartShopInfo')) || [];
        const businessInfo = restaurantInfo && restaurantInfo.restaurantName ? restaurantInfo : shopInfo;

        if (!businessInfo || !businessInfo.delivery_fee) {
            setDeliveryFee(0);
            return 0;
        }

        const minimumSum = businessInfo.minimum_sum_of_delivery_free;
        const deliveryFee = businessInfo.delivery_fee;

        if ((minimumSum && totalPrice >= minimumSum) || deliveryMode === 'pickup') {
            setDeliveryFee(0);
            return 0;
        } else {
            setDeliveryFee(deliveryFee);
            return deliveryFee;
        }
    }

    useEffect(() => {
        eventBus.subscribe('cartItemAdded', handleCartItemAdded);
        eventBus.subscribe('cartItemRemoved', handleCartItemRemoved);
        eventBus.subscribe('cartItemQuantityUpdated', handleCartItemQuantityUpdated);
        eventBus.subscribe('cartCleared', handleCartCleared);
        eventBus.subscribe('couponApplied', handleCouponApplied);
        eventBus.subscribe('deliveryModeChanged', handleDeliveryModeChanged);

        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        const initialTotalPrice = storedCart.reduce((total, item) => total + calculateItemPrice(item), 0);

        setTotalPrice(initialTotalPrice);

        calculateTotal();

        return () => {
            eventBus.unsubscribe('cartItemAdded', handleCartItemAdded);
            eventBus.unsubscribe('cartItemRemoved', handleCartItemRemoved);
            eventBus.unsubscribe('cartItemQuantityUpdated', handleCartItemQuantityUpdated);
            eventBus.unsubscribe('cartCleared', handleCartCleared);
            eventBus.unsubscribe('couponApplied', handleCouponApplied);
            eventBus.unsubscribe('deliveryModeChanged', handleDeliveryModeChanged);
        };
    }, [totalPrice, appliedCoupon, deliveryMode]);

    const calculateTotal = () => {
        const couponTotal = (appliedCoupon?.discount || 0);
        const deliveryTotal = updateDeliveryFee();
        const total = (totalPrice + deliveryTotal) - couponTotal;

        setCouponTotal(couponTotal);
        setTotal(Math.max(total, 0));
    };

    return { total: total, totalPrice, deliveryFee, couponTotal };
};

export default useCartTotalPrice;

import React, { useState } from 'react';
import eventBus from '../../eventBus';
import ExtrasModal from '../modal/ExtrasModal';

const ProductItem = ({ id, media, name, price, discount_price, category_id, ingredients, has_extras, extrasproducts, showAlertModal }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const addToCart = () => {
    const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
    const currentShop = JSON.parse(localStorage.getItem('currentShopInfo'));
    const cartShop = JSON.parse(localStorage.getItem('cartShopInfo'));

    const cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurantInfo'));

    if (cartRestaurant) {
      showAlertModal("Уведомление!", `Нужно закончить оформление заказа в ресторане ${cartRestaurant.restaurantName}!`);
      return;
    }

    if (cartShop && currentShop && currentShop.shopName !== cartShop.shopName) {
      showAlertModal("Уведомление!", `Нужно закончить оформление заказа в магазине ${cartShop.shopName}!`);
      return;
    }

    if (currentShop && currentShop.shop_has_worked === false) {
      showAlertModal("Уведомление!", `Магазин закрыт!`);
      return;
    }

    localStorage.setItem('cartShopInfo', JSON.stringify(currentShop));
    localStorage.removeItem('cartRestaurantInfo');

    const existingItem = storedCart.find(item => item.product_id === id);

    if (existingItem) {
      increaseQuantity(storedCart, existingItem);
    } else {
      addItemToCart(storedCart);
    }
  };

  const handleAddToCartWithExtras = (cartItem) => {
    const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
    const currentShop = JSON.parse(localStorage.getItem('currentShopInfo'));
    const cartShop = JSON.parse(localStorage.getItem('cartShopInfo'));

    const cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurantInfo'));

    if (cartRestaurant) {
      showAlertModal("Уведомление!", `Нужно закончить оформление заказа в ресторане ${cartRestaurant.restaurantName}!`);
      return;
    }

    if (cartShop && currentShop && currentShop.shopName !== cartShop.shopName) {
      showAlertModal("Уведомление!", `Нужно закончить оформление заказа в магазине ${cartShop.shopName}!`);
      return;
    }

    if (currentShop && currentShop.shop_has_worked === false) {
      showAlertModal("Уведомление!", `Магазин закрыт!`);
      return;
    }

    localStorage.setItem('cartShopInfo', JSON.stringify(currentShop));

    storedCart.push(cartItem);
    localStorage.setItem('cartItems', JSON.stringify(storedCart));
    eventBus.publish('cartItemAdded', cartItem);

    closeModal();
  };

  const addItemToCart = (storedCart) => {
    const uniqueId = `${id}-${new Date().getTime()}`;

    const newItem = {
      uniqueId,
      id: null,
      imageUrl: media.url,
      name,
      price: discount_price || price,
      quantity: 1,
      product_id: id,
      category_id,
      extras: []
    };

    storedCart.push(newItem);
    localStorage.setItem('cartItems', JSON.stringify(storedCart));
    eventBus.publish('cartItemAdded', newItem);
  }

  const increaseQuantity = (storedCart, cartItem) => {
    cartItem.quantity += 1;

    const updatedCart = storedCart.map((item) =>
      item.product_id === id ? { ...item, quantity: cartItem.quantity } : item
    );
    localStorage.setItem('cartItems', JSON.stringify(updatedCart));

    const priceChange = storedCart.find((item) => item.product_id === id).price;

    eventBus.publish('cartItemQuantityUpdated', { product_id: id, localQuantity: cartItem.quantity, priceChange });
  }

  return (
    <div className="q-intersection example-item">
      <div className="q-card q-ma-sm section-shop__goods-item">
        <div className="section-shop__goods-block-img">
          <img src={media ? media.url : ''} alt="Product" className="section-shop__goods-img" />
          <div className="section-shop__goods-composition-block text-center">
            <h6 className="section-shop__goods-composition-heading">Состав</h6>
            <span className="section-shop__goods-composition-text">{name}</span>
            <span className="section-shop__goods-composition-text" dangerouslySetInnerHTML={{ __html: ingredients }}></span>
          </div>
        </div>
        <div className="q-card__section q-card__section--vert q-pa-none">
          <div className="section-shop__goods-info">
            <h3 className="sectoin-shop__goods-heading">{name}</h3>
          </div>
        </div>
        <div className="q-card__section q-card__section--vert q-pa-none section-shop__goods-btn" bottom="">
          <div className="section-shop__goods-btn">
            {has_extras ? (
              <button className="btn" onClick={openModal}>
                В корзину {discount_price ? `${discount_price} ₽ ` : ''}<span style={{ textDecoration: discount_price ? 'line-through' : 'none' }}>{price} ₽</span>
              </button>
            ) : (
              <button className="btn" onClick={addToCart}>
                В корзину {discount_price ? `${discount_price} ₽ ` : ''}<span style={{ textDecoration: discount_price ? 'line-through' : 'none' }}>{price} ₽</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ExtrasModal
          id={id}
          image={media.url}
          name={name}
          price={price}
          discount_price={discount_price}
          extras={extrasproducts}
          onClose={closeModal}
          onAddToCart={handleAddToCartWithExtras}
        />
      )}
    </div>
  );
};

export default ProductItem;

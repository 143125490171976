import React, { useState } from 'react';
import { useGetCategoryFoodsQuery } from '../../modules/restaurants/api';
import { getCityData } from '../../utils/city-data';
import FoodItem from '../cards/FoodItem';
import Spinner from '../spinner/Spinner';
import AlertModal from '../modal/AlertModal';
import { useInView } from 'react-intersection-observer';

const CategoryFoodsList = ({ city, categoryId, restaurantId, categoryName }) => {
  const cityData = getCityData(city);
  const params = { city: cityData.url, category_id: categoryId, restaurant_id: restaurantId };
  const { data: foods, isLoading, isError } = useGetCategoryFoodsQuery(params);

  const [modalData, setModalData] = useState(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const showAlertModal = (title, message) => {
    setModalData({ title, message });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>Error loading restaurants</p>;
  }

  const foodsArray = Object.values(foods);

  return (
    <div ref={ref} className='category-foods-block'>
      {inView && (
        <>
          {modalData && <AlertModal title={modalData.title} message={modalData.message} onClose={() => setModalData(null)} />}

          <h2 className="second-heading">{categoryName}</h2>
          <ul className="row justify-content-start">
            {foodsArray.map((food, index) => (
              <FoodItem key={index} {...food} showAlertModal={showAlertModal} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CategoryFoodsList;

import React from 'react';
import { useGetShopsQuery } from '../../modules/shops/api';
import { getCityData } from '../../utils/city-data';
import ShopCard from '../cards/ShopCard';
import Spinner from '../spinner/Spinner';

const ShopList = ({ city }) => {
    const cityData = getCityData(city);
    const { data: shops, isLoading, isError } = useGetShopsQuery(cityData.url);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        return <p>Error loading shops</p>;
    }

    return (
        <ul className="section-restaurants__list shops-list">
            {shops.data.map((shop, index) => (
                <ShopCard
                    key={index}
                    name={shop.name}
                    imageUrl={shop.media.length > 0 ? shop.media[0].url : ''}
                    link={`/City-${city}/shop/shop-${shop.name}`}
                />
            ))}
            <li className="section-restaurants__item section-restaurants__item-empty">
                <div className="section-restaurants__block-text-empty">
                    <p className="section-restaurants__text-empty">СКОРО!!! <br />НОВЫЙ <br />МАГАЗИН</p>
                </div>
            </li>
        </ul>
    );
};

export default ShopList;

import React, { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

const PaymentQRModal = ({ qrCodeSvg, onClose }) => {
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const modalElement = document.getElementById('paymentQRModal');
        const modalInstance = new Modal(modalElement);

        setModal(modalInstance);

        return () => {
            modalInstance.hide();
        };
    }, []);

    useEffect(() => {
        if (modal) {
            modal.toggle();
        }
    }, [modal]);

    const closeModal = () => {
        onClose();
    };

    return (
        <div className="modal fade" id="paymentQRModal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <h5 className="modal-title">Для оплаты</h5>
                        <p className="mb-4">отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона</p>
                        <div dangerouslySetInnerHTML={{ __html: qrCodeSvg }} className='my-4' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentQRModal;

import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    // Другие параметры
});

export const subscribeToChannel = (channelName, eventName, callback) => {
    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, callback);

    return () => {
        pusher.unsubscribe(channelName);
    };
};
